
import { createSlice } from '@reduxjs/toolkit';
import { selectLine, selectLineAndAncestors, selectLinesAndSublinesAsListWithLevels } from './linesSlice';
import { fetchScoreById, selectScore } from './scoreSlice';

/**
 * Middleware to access state of other slices for particular actions.
 * Enrich expandUntill actions with lines.
 * @param {*} storeAPI 
 * @returns 
 */
export const middleware = storeAPI => next => action => {
  if (action.type == 'interface/expandUntill') {
    const state = storeAPI.getState(),
          score = selectScore(state),
          flattenedLines = selectLinesAndSublinesAsListWithLevels(state, score.mainline);

    action.payload = {
      ...action.payload,
      flattenedLines: flattenedLines
    }  
  }

  return next(action)
}

export const INTERFACE_MODES = {
  'PLAYER': 'player',
  'EDITOR': 'editor'
};


const initialState = {
  /** Index which holds which lines are expanded  */
  expanded: {},
  activeAttachment: null,
  mode: INTERFACE_MODES.EDITOR
  // expanded: {}
};

export const interfaceSlice = createSlice({
  name: 'interface',
  initialState,
  reducers: {
    toggleLine: (state, action) => {
      const { id } = action.payload;
      state.expanded[id] = !state.expanded[id];
    },

    expandLine: (state, action) => {
      const { id } = action.payload;
      if (!state.expanded[id]) {
        state.expanded[id] = !state.expanded[id];
      }
    },

    collapaseLine: (state, action) => {
      const { id } = action.payload;
      if (!state.expanded[id]) {
        state.expanded[id] = !state.expanded[id];
      }
    },

    collapseAll: (state) => {
      for (let key in state.expanded) {
        state.expanded[key] = false;
      }
    },

    expandAll: (state) => {
      for (let key in state.expanded) {
        state.expanded[key] = true;
      }
    },

    expandUntill: (state, action) => {
      const { level, flattenedLines } = action.payload;

      flattenedLines.forEach(([lineLevel, line]) => {
        if (lineLevel < level) {
          state.expanded[line.id] = true;
        }
        else {
          state.expanded[line.id] = false;
        }
      });
    },

    showAttachment: (state, action) => {
      state.activeAttachment = action.payload;
    },

    closeAttachment: (state, action) => {
      state.activeAttachment = null;
    },

    openPlayer: (state) => {
      state.mode = INTERFACE_MODES.PLAYER;
      for (let key in state.expanded) {
        state.expanded[key] = false;
      }
    },

    openEditor: (state, action) => {
      state.mode = INTERFACE_MODES.EDITOR;
      for (let key in state.expanded) {
        state.expanded[key] = false;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScoreById.fulfilled, (state, action) => {
      state.expanded = Object.fromEntries(Object.keys(action.payload.lines).map((id) => ([ id, false ])))
    })
  }
});

/**
 * Test whether a line is expanded
 * @param {string} id 
 * @returns boolean
 */
export const selectLineExpanded = (state, id) => state.interface.expanded[id];

/**
 * Returns 
 */
export const selectLinesExpandedState = (state) => state.interface.expanded;

export const selectActiveAttachment = (state) => state.interface.activeAttachment;

/*
* Check whether line is visible, by ensuring none of its parents
* are collapsed
*/
export const selectLineVisible = (state, id) => {
  const line = selectLine(state, id),
        expanded = selectLineExpanded(state, id);

  if (!expanded) {
    return false
  }
  else if (line.parentId) {
    return selectLineVisible(state, line.parentId);
  }
  else {
    return true;
  }
}

export const selectFirstVisibleParent = (state, lineId) => {
  const tree = selectLineAndAncestors(state, lineId);

  if (tree) {
    // Reverse loop over ancestor list
    // To find closest visible parent
    for (let i=tree.length-1; i > 0; i--) {
      if (!state.interface.expanded[tree[i].id]) {
        // Line is not expanded therefor its children are hidden.
        // return this line.
        return tree[i];
      }
    }
  
    return tree[0];
  }

  return null;
}

export const { 
  closeAttachment, collapseAll, collapaseLine,
  expandAll, expandUntill, expandLine, 
  openEditor, openPlayer, showAttachment, toggleLine
} = interfaceSlice.actions;

export default interfaceSlice.reducer;