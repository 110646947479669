export function getCSRF () {
  let cookies = Object.fromEntries(
    document.cookie.split(';')
    // Split into pair chunks => ['key=value', ... ]
    .map((rawPair) => rawPair.trim().split('=', 2))
    // Trim and split on equal
  );

  if ('csrftoken' in cookies) {
    return cookies['csrftoken']
  }
}