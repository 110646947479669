import { RANGE_TYPES } from '../constants';

export function LineAlternative ({ alternative })  {
  return <span className="alternative">
    { alternative.type == RANGE_TYPES.exact && <>{ alternative.value }</> }
    { alternative.type == RANGE_TYPES.minimal && <>{ alternative.value }+</> }
    { alternative.type == RANGE_TYPES.range && <>{ alternative.value[0] }-{ alternative.value[1] }</> }
  </span>
}

export default LineAlternative;