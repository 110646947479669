import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScoreById } from './scoreSlice';
import { selectScores, fetchScoreList } from './scoreListSlice';
import { FETCH_STATUSES } from './constants';

export function ScoreList () {
  const dispatch = useDispatch(),
        scoreList = useSelector(state => state.scoreList),
        scores = useSelector(selectScores);

  useEffect(() => {
    if (scoreList.status === FETCH_STATUSES.IDLE)  {
      dispatch(fetchScoreList());
    }
  }, [scoreList.status, dispatch]);

  if (scoreList.status === FETCH_STATUSES.IDLE) {
    return <>Initiating</>;
  }
  else if (scoreList.status === FETCH_STATUSES.PENDING) {
    return <>Loading score</>;
  } else if (scoreList.status === FETCH_STATUSES.FAILED) {
    return <>Loading score failed</>;
  }
  else {
    return <ul style={ { listStyleType: 'none', margin: 0, padding: 0 } }>
      { scores.map(score => <li key={ score.id } style={ { cursor: 'pointer', marginTop: '1ch' } } onClick={() => dispatch(fetchScoreById(score.id)) }>{ score.title }</li> )}
    </ul>
  }
}