import { translate as t } from '../../../utils/translate';
import { format as f } from '../../../utils/format';
import { CONTINGENT_MODES } from '../constants';

/**
 * Show the condition dot and its popup when:
 * - there is a condition
 * - the line is contigent and has a necessary when
 * @param {*} param0 
 * @returns 
 */
export function LineCondition ({ condition, contingent, necessary_when })  {
  if (contingent && contingent.value) {
    if (contingent.mode == CONTINGENT_MODES.CONDITION) {
      return <span className="line__condition">
        { contingent.value && <span className="label">
          { f(t('si {}, je peux'), contingent.value) }
        </span> }
    </span>
    }
    else if (contingent.mode == CONTINGENT_MODES.OBLIGATION) {
      return <span className="line__condition">
        { contingent.value && <span className="label">
          { f(t('si {}, je dois'), contingent.value) }
        </span> }
      </span>
    }
    else if (contingent.mode == CONTINGENT_MODES.IMPERTATIF) {
      return <span className="line__condition">
        { contingent.value && <span className="label">
          { f(t('si et seulement si {}, je dois'), contingent.value) }
        </span> }
      </span>
    }
  }
  else if (necessary_when) {
    return <span className="line__condition">
      <span className="label">
        ! { necessary_when && <>{ f(t('si {}, je dois'), contingent.value)}</>}
      </span>
  </span>
  }
  else if (condition) {
    return <span className="line__condition">
      <span className="label">
        { t('quand')} <strong>{ condition }</strong>
      </span>
    </span>
  }
  else {
    return <span className="line__condition__spacer"></span>
  }
}

export default LineCondition;