import uuidv4 from './uuid'
import { LINE_TYPE_REGULAR, TRANSITION_DEFAULT } from '../features/score/constants'

export function makeNewLine (attrs) {
  let line = {
    "id": uuidv4(),
    "parentId": null,
    "title": "",
    "sublines": [],
    "tag": null,
    "type": LINE_TYPE_REGULAR,
    "indications": null,
    "piece_jointe": "",
    "terme": null,
    "alternative": null,
    "goto": null,
    "adresse": null,
    "module_": false,
    "actant": null,
    "boucle": null,
    "alternative_symbole": "",
    "imperative": false,
    "contingent": false,
    "commandement": null,
    "code": null,
    "attachment": null,
    "aspect": null,
    "condition": null,
    "focused": 0,
    "destination": null,
    "link": null,
    "transition": TRANSITION_DEFAULT,
    "attachments": []
  }

  return {
    ...line,
    ...attrs
  }
}

function newSublineFor (parentId, attrs) {
  return makeNewLine({
    ...attrs,
    parentId: parentId
  });
}

export default newSublineFor