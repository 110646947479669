import React from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Organon from './features/organon/Organon';

function App() {
  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
        <Organon />
      </DndProvider>
    </div>
  );
}

export default App;
