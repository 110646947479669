import { createSlice, current } from '@reduxjs/toolkit';
import { selectScore } from './scoreSlice';
import { selectLine, selectLineAndSublinesAsList } from './linesSlice';
import { PLAYER_LINE_MODES, PLAYER_LINE_MODE_SIMULTANEOUS, PLAYER_RENDER_MODE_HIDE, PLAYER_RENDER_MODE_RENDER, PLAYER_RENDER_MODE_STUB, RANGE_TYPES, TAG_ACCUMULATIVE, TAG_ACCUMULATIVE_RANDOM, TAG_SIMULTANEOUS, TAG_SUCCESSIVE, TAG_SUCCESSIVE_RANDOM } from './constants';
import { fetchScoreById } from './scoreSlice';
import { openPlayer } from './interfaceSlice';

/*
  @TODO add a nextline / previousline on queue entries ?
*/


/*

  const initialState = {
    score: null, // Id of currentscore
    information: null, // Will hold info for the lines in the player
    currentLine: null, // Id of the current line,
    mainline: null,
    queue: {}
  }

  queueEntry

  {
    str:lineId {
      parentId: str, // id of parent to make building a reverse tree easier,
      nextSiblingId: str | null,
      sublines: [ str:id, ... ]  // the sublines to include
      mode: sequential | simultaneous // whether the sublines are displayed parrallelly or sequentially,
      count: int. // amount of times the line is played
      repeat: int, // amount of times this line should be played
      alternative: , // alternative if there is one
      tag: str, // Tag of line. Rather than in score structure set tag on parent. 
    }
  }
*/

/**
 * if not repeat → render qeustion
 * if not sublines and alternative → render question
 * it not alternative set sublines as copy of originalSublines on 
 */

/**
 * Middleware to access state of other slices for particular actions.
 * Enrich expandUntill actions with lines.
 * @param {*} storeAPI 
 * @returns 
 */
export const middleware = storeAPI => next => action => {
  // Add all lines of the current score to the payload.
  if (action.type == 'player/initPlayer') {
    const state = storeAPI.getState(),
          mainline = selectScore(state).mainline,
          lineList = selectLineAndSublinesAsList(state, mainline);

    if (action.payload == undefined) {
      action.payload = {}
    }
    action.payload.mainline = mainline;
    action.payload.lines = Object.fromEntries(lineList.map(l => [l.id, l]));
  } 

  return next(action)
}


const initialState = {
  score: null, // Id of currentscore
  information: null, // Will hold info for the lines in the player
  currentLine: null, // Id of the current line,
  mainline: null,
  lines: {},
  queue: {},
  canStep: true
}


function selectDeepestEligibleSubline (state, lineId) {
  let queueEntry = state.queue[lineId];

  if (queueEntry.repeat === null || queueEntry.count < queueEntry.repeat) {
    // Make sure the line hasn't been played
    if (queueEntry.needInformation) {
      // Need more information on this line.
      return lineId
    } else {
      if (queueEntry.sublines.length == 0) {
        // If the entry does not have children make sure its parent is not simultaneous
        if (queueEntry.parentId) {
          if (state.queue[queueEntry.parentId].mode !== PLAYER_LINE_MODE_SIMULTANEOUS) {
            return lineId;
          }
        }
        else {
          // Not sure this is ever possible? Is a score with only one line?
          return lineId;
        }
      }
      else {
        for (let sublineId of queueEntry.sublines) {
          // Loop through children. See whether there is an eligble line there.
          let next = selectDeepestEligibleSubline(state, sublineId);
          if (next) {
            return next;
          }
        }

        // None of the sublines are eligible return line itself
        return lineId
      }
    }
  }

  return null;
}


function isLastSubline (state, lineId) {
  let parentId = state.queue[lineId].parentId,
      parent = state.queue[parentId];
  
  return parent.sublines[parent.sublines.length-1] == lineId;
}


function makeQueueEntry (lines, line) {
  let needInformation = false,
      alternative = false,
      contingent = false,
      repeat = 1;

  if (line.boucle) {
    if (line.boucle.type === RANGE_TYPES.exact) {
      repeat = line.boucle.value;
    }
    else {
      needInformation = true;
      repeat = null;
    }
  }

  if (line.alternative) {
    needInformation = true;
    alternative = line.alternative;
  }

  if (line.contingent) {
    needInformation = true;
    contingent = true;
  }

  const tag = (line.sublines.length > 0) ? lines[line.sublines[0]].tag : null;

  if (tag === TAG_SUCCESSIVE_RANDOM || tag === TAG_ACCUMULATIVE_RANDOM) {
    needInformation = true;
  }

  return [line.id, {
    parentId: line.parentId,
    // nextSiblingId: getNextSiblingId(lines, line.id),
    // Derive repetition from loop field
    repeat: repeat,
    // If there is a loop for this line the amount of repetitions has to be decided by the performer
    count: 0,
    // Derive mode from tag (// simultanéité → simultaneous (For now make it inherit. Question for Joris?), otherwise sequential)
    mode: (line.sublines.length > 0) ? getLineMode(lines[line.sublines[0]]) : null, // :-/
    tag: tag,
    transition: (line.sublines.length > 0) ? lines[line.sublines[0]].transition : null, // :-/
    // Add children to the subLines property
    alternative: alternative, 
    sublines: (alternative) ? null : line.sublines,
    // If the line has an alternative set the performer needs to decide which lines will be performed
    contingent: contingent,
    needInformation: needInformation
  }]
}
    
    
// Make list of queue entries [lineId, QueueEntry ]
// also recursively for all the sublines
let makeQueueEntriesRecursive = (lines, lineId) => ([ makeQueueEntry(lines, lines[lineId]), ...lines[lineId].sublines.flatMap((lineId) => makeQueueEntriesRecursive(lines, lineId)) ] );

function markPlayedRecursively (state, lineId) {
  let queueEntry = state.queue[lineId];
  queueEntry.count += 1;
  if (queueEntry.count >= queueEntry.repeat 
    && queueEntry.parentId
    // In situations where the entry's parent is simultaneous, but
    // siblings have no sublines continue marking played on the parent.
    && selectDeepestEligibleSubline(state, queueEntry.parentId) === queueEntry.parentId)
  {
    markPlayedRecursively(state, queueEntry.parentId);
  }
  else {
    if (queueEntry.alternative) {
      queueEntry.sublines = null;
      queueEntry.needInformation = true;
    }

    state.lines[lineId].sublines.forEach((lineId) => {
        // Reset queue entries for children to force new decisions
        makeQueueEntriesRecursive(state.lines, lineId).forEach(([ lineId, queueEntry ]) => {
        state.queue[lineId] = queueEntry;
      });
    });
  }
}

function goToNextLine (state) {
  state.currentLine = selectDeepestEligibleSubline(state, state.mainline);   
  if (state.currentLine) {
    state.canStep = !state.queue[state.currentLine].needInformation;
  }
  else {
    state.canStep = false;
  }
}


export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    markCurrentLinePlayed: (state) => {
      // If there is a current line mark it played
      if (state.currentLine) {
        markPlayedRecursively(state, state.currentLine);
      }
      goToNextLine(state);
    },

    skipQueueEntry: (state, action) => {
      let lineId = action.payload;
      let queueEntry = state.queue[lineId];

      if (queueEntry.needInformation) {
        queueEntry.needInformation = false;
        queueEntry.repeat = 1;
      }

      queueEntry.count = queueEntry.repeat;
      
      if (isLastSubline(state, lineId)) {
        markPlayedRecursively(state, queueEntry.parentId);
      }

      goToNextLine(state);
    },

    /**
     * payload: {
     *  id: str:lineId of queueuEntry
     *  repeat: value
     *  sublines: value
     * }
     * @param {*} state 
     * @param {*} action 
     */
    updateQueueEntry: (state, action) => {
      state.queue[action.payload.id].repeat = action.payload.repeat;
      // Get sublines in new order, but only include selected lines.
      state.queue[action.payload.id].sublines = action.payload.order.filter((id) => action.payload.sublines.indexOf(id) > -1);
      state.queue[action.payload.id].contingent = false;
      state.queue[action.payload.id].needInformation = false;

      if (state.queue[action.payload.id].tag == TAG_SUCCESSIVE_RANDOM) {
        state.queue[action.payload.id].tag = TAG_SUCCESSIVE;
      }

      if (state.queue[action.payload.id].tag == TAG_ACCUMULATIVE_RANDOM) {
        state.queue[action.payload.id].tag = TAG_ACCUMULATIVE;
      }

      // Next line might be one of the sublines of updated entry.
      state.currentLine = selectDeepestEligibleSubline(state, state.mainline);
      // Mark that score whether continue
      state.canStep = !state.queue[state.currentLine].needInformation;
    },
    setCurrentLine: (state, action) => {
      state.currentLine = action.payload
    },
    setMetadata: (state, action) => {
      // state.information
    },
    initPlayer: (state, action) => {
      let mainline = action.payload.mainline,
          lines = action.payload.lines;
      
      state.lines = lines;
      state.mainline = mainline;
      state.queue = Object.fromEntries(makeQueueEntriesRecursive(lines, mainline));
      goToNextLine(state);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScoreById.fulfilled, (state, action) => {
        state.score = action.payload.id; // Id of currentscore
        //   information: null, // Will hold info for the lines in the player
        state.currentLine = null; // Id of the current line,
        state.mainline = null;
        state.lines = {};
        state.queue = {};
        state.canStep = true;
      })
      .addCase(openPlayer, (state, action) => {
        // Reset on opening player to ensure actions are loaded.
        // Setting score through interface is a bit of an anti-pattern :-S
        state.currentLine = null; // Id of the current line,
        state.mainline = null;
        state.lines = {};
        state.queue = {};
        state.canStep = true;
      })
  }
});

// /**
//  * Get line repeat value by examining the boucle property of a line
//  * @param {*} line 
//  * @returns 
//  */
// function getRepeatValue(line) {
//   if (line.boucle) {
//     if (line.boucle.type == RANGE_TYPES.exact) {
//       // Exact amount of repetitions
//       return line.boucle.value
//     }
//     else if (line.boucle.type == RANGE_TYPES.minimal) {
//       // Minimum repeat
//       return line.boucle.value
//     }
//     else {
//       // Range repeat
//       return line.boucle.value[0];
//     }
//   }

//   return 1;
// }

function getLineMode (line) {
  if (line.tag == TAG_ACCUMULATIVE || line.tag == TAG_ACCUMULATIVE_RANDOM) {
    return PLAYER_LINE_MODES.ACCUMULATIVE;
  }
  else if (line.tag == TAG_SIMULTANEOUS) {
    return PLAYER_LINE_MODES.SIMULTANEOUS;
  }
  else if (line.tag == TAG_SUCCESSIVE || line.tag == TAG_SUCCESSIVE_RANDOM) {
    return PLAYER_LINE_MODES.SUCCESSIVE;
  }
  return null;
}

export const { setCurrentLine, updateQueueEntry, markCurrentLinePlayed, skipQueueEntry } = playerSlice.actions;


export function initPlayer () {
  return function (dispatch, getState) {
    dispatch({ type: 'PLAYER_RESET_UNDO' });
    dispatch(playerSlice.actions.initPlayer());
  }
}

export default playerSlice.reducer;