import React from "react";
import { translate as t } from '../../../utils/translate';
import HelpShortcut from '../../../utils/HelpShortcut';
import { TRANSITIONS } from "../constants";
import { useDispatch } from 'react-redux';
import { setTransition } from '../lineEditorSlice';


export default function TransitionField ({ transition }) {
  const dispatch = useDispatch();

  return <section className="fieldgroup">
    <label className="inline">
      <HelpShortcut target="#transition">{ t('Transition') }</HelpShortcut>
      <select name="type" value={ transition } onChange={ (e) => { dispatch(setTransition(e.target.value)) } }>
          <option value={ TRANSITIONS.CHAINED } >
            { t(TRANSITIONS.CHAINED) }
          </option>
          <option value={ TRANSITIONS.CROSSFADE } >
            { t(TRANSITIONS.CROSSFADE) }
          </option>
          <option value={ TRANSITIONS.CUT } >
            { t(TRANSITIONS.CUT) }
          </option>
        </select>
    </label>
  </section>
}