import { createSlice } from '@reduxjs/toolkit';
import { ASPECTS, POINT_TO_DEFAULT, RANGE_DEFAULT_TYPE, RANGE_DEFAULT_VALUES, RANGE_TYPES, LINK_DEFAULT, LINE_TYPES, LINE_TYPE_REGULAR, LINE_TYPE_LINK, VALID_CONTINGENT_MODES, CONTINGENT_DEFAULT } from './constants'; 
import { isValidContingentMode, isValidLinkMode, isValidTransition } from './validators';

export const MODE_CREATE = 'create';

export const MODE_EDIT = 'edit';

const initialState = {
  visible: false,
  line: null,
  mode: null
};

/**
 * Sets the rangefield type and defines the new value.
 * New value depends on the next and previous type.
 * @param {*} field 
 * @param {*} action 
 * @returns 
 */
const rangeFieldSetType = (field, action) => {
  const nextType = action.payload.type,
        fieldName = action.payload.fieldName;
  let nextValue;
  
  if (nextType === RANGE_TYPES.range) {
    // New type is range, set value to be a range too
    if (field && field.value && typeof field.value == 'number') {
      // If there is already a numeric value set it as
      // the lower bound of the range
      nextValue = [field.value, field.value + 1]
    }
    else {
      nextValue = RANGE_DEFAULT_VALUES[fieldName][nextType];
    }
  }
  else if (nextType === RANGE_TYPES.minimal || nextType === RANGE_TYPES.exact) {
    // New type has a single value
    if (field && field.value && typeof field.value == 'number') {
      nextValue = field.value;
    }
    else if (field && field.value && typeof field.value == 'object') {
      nextValue = field.value[0];
    }
    else {
      nextValue = RANGE_DEFAULT_VALUES[fieldName][nextType];
    }
  }
  else {
    // Undetermined
    nextValue = null;
  }

  return {
    type: nextType,
    value: nextValue
  }
}


const rangeFieldSetValue = (field, action) => {
  let nextValue;

  if (field && (field.type === RANGE_TYPES.exact || field.type === RANGE_TYPES.minimal)) {
    // Next alternative value is single, always accept, but force integer
    nextValue = parseInt(action.payload)
  }
  else {
    // Is a range, ensure lower bound is at least zero
    // and upper bound is bigger than lower bound
    const lower = Math.max(0, parseInt(action.payload[0])),
          upper = Math.max(lower + 1, parseInt(action.payload[1]));

    nextValue = [ lower, upper ]
  }

  return {
    type: field.type,
    value: nextValue
  }
}

const rangeFieldDisable = () => null;

const rangeFieldEnable = (type=RANGE_DEFAULT_TYPE, value=RANGE_DEFAULT_VALUES[RANGE_TYPES.undetermined]) => ({
    type: type,
    value: value
  })

export const lineEditorSlice = createSlice({
  name: 'lineEditor',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // payload: { line: Line, mode: MODE_EDIT | MODE_CREATE }
    activateEditor: (state, action) => {
      state.visible = true;
      state.line = action.payload.line;
      state.mode = action.payload.mode;
    },
    deactivateEditor: (state) => {
      state.visible = false;
      state.line = null;
    },
    showEditor: (state) => {
      state.visible = true
    },
    hideEditor: (state) => {
      state.visible = false
    },
    // payload: Line
    setLine: (state, action) => {
      state.line = action.payload
    },
    // payload: string in LINE_TYPES
    setLineType: (state, action) => {
      state.line.type = action.payload;

      if (state.line.type == LINE_TYPE_LINK
          && state.line.link == null
      ){
        // @FIXME: is this a side effect?
        state.line.link = LINK_DEFAULT;
      }
    },
    enableAlternative: (state, action) => {
      state.line.alternative = rangeFieldEnable(action.payload.type, action.payload.value);
    },
    enableAspect: (state) => {
      state.line.aspect = ASPECTS[0];
    },
    enableBoucle: (state) => {
      state.line.boucle = rangeFieldEnable();
    },
    enablePointTo: (state) => {
      state.line.pointTo = POINT_TO_DEFAULT
    },
    disableAlternative: (state) => {
      state.line.alternative = rangeFieldDisable();
    },
    disableAspect: (state) => {
      state.line.aspect = null;
    },
    disableBoucle: (state) => {
      state.line.boucle = rangeFieldDisable();
    },
    disablePointTo: (state) => {
      state.line.pointTo = null;
    },
    // payload: string
    setAspect: (state, action) => {
      state.line.aspect = action.payload
    },
    // payload: string
    setActant: (state, action) => {
      state.line.actant = action.payload
    },
    // payload: string
    setAdresse: (state, action) => {
      state.line.adresse = action.payload
    },
    // payload: string in RANGE_TYPES
    setAlternativeRangeType: (state, action) => {
      action.payload = {
        type: action.payload,
        fieldName: 'alternative'
      }
      state.line.alternative = rangeFieldSetType(state.line.alternative, action)
    },
    // payload: null|integer|[integer, integer]
    setAlternativeValue: (state, action) => {
      state.line.alternative = rangeFieldSetValue(state.line.alternative, action)
    },
    // payload: string in RANGE_TYPES
    setBoucleRangeType: (state, action) => {
      action.payload = {
        type: action.payload,
        fieldName: 'boucle'
      }
      state.line.boucle = rangeFieldSetType(state.line.boucle, action)
    },
    // payload: null|integer|[integer, integer]
    setBoucleValue: (state, action) => {
      state.line.boucle = rangeFieldSetValue(state.line.boucle, action)
    },
    // payload: string
    setCode: (state, action) => {
      state.line.code = action.payload
    },
    // payload: string
    setCommandement: (state, action) => {
      state.line.commandement = action.payload
    },
    // payload: string
    setCondition: (state, action) => {
      state.line.condition = action.payload
    },
    // payload: boolean
    setContingent: (state, action) => {
      if (action.payload) {
        state.line.contingent = CONTINGENT_DEFAULT
      }
      else {
        state.line.contingent = null;
      }
    },
    setContingentMode: (state, action) => {
      if (isValidContingentMode(action.payload)) {
        state.line.contingent.mode = action.payload;
      }
    },
    setcontingentValue: (state, action) => {
      state.line.contingent.value = action.payload;
    },
    // payload: string
    setNecessaryWhen: (state, action) => {
      const newVal = action.payload.trim();
      state.line.necessary_when = (newVal) ? newVal : null;
    },
    // payload: boolean
    setImperative: (state, action) => {
      state.line.imperative = (action.payload) ? true : false
    },
    // payload: string
    setDestination: (state, action) => {
      state.line.destination = action.payload
    },
    // payload: string
    setIndications: (state, action) => {
      state.line.indications = action.payload
    },
    // payload: boolean
    setModule: (state, action) => {
      state.line.module_ = (action.payload) ? true : false
    },
    // payload: POINT_TO_MODE
    setPointToMode: (state, action) => {
      state.line.pointTo.mode = action.payload;
    },
    // payload: line:string
    setPointToLine: (state, action) => {
      state.line.pointTo.line = action.payload;
    },
    enableLinkField: (state, action) => {
      state.line.link = LINK_DEFAULT;
    },
    disableLinkField: (state, action) => {
      state.line.link = null;
    },
    // payload: LINK_MODE
    setLinkMode: (state, action) => {
      if (isValidLinkMode(action.payload)) {
        state.line.link.mode = action.payload;
      }
      else {
        state.line.link.mode = null;
      }
    },
    // payload: line:string
    setLinkLine: (state, action) => {
      state.line.link.line = action.payload;
    },
    // payload: string
    setTag: (state, action) => {
      state.line.tag = action.payload
    },
    // payload: string
    setTerme: (state, action) => {
      state.line.terme = action.payload
    },
    // payload: string
    setTitle: (state, action) => {
      state.line.title = action.payload
    },
    // payload: TRANSITION_TYPE
    setTransition: (state, action) => {
      if (isValidTransition(action.payload)) {
        state.line.transition = action.payload;
      }
    },
    addAttachment: (state, action) => {
      state.line.attachments.push(action.payload);
    },
    removeAttachment: (state, action) => {
      const attachmentIndex = state.line.attachments.indexOf(action.payload);

      if (attachmentIndex > -1) {
        state.line.attachments.splice(attachmentIndex, 1);
      }
    }
  }
});

export const {
  activateEditor, deactivateEditor, disableAlternative, disableAspect, 
  disableBoucle, disablePointTo, enableAlternative, enableAspect,
  enableBoucle, enablePointTo, showEditor, hideEditor, setLine, setAspect,
  setActant, setAdresse, setAlternativeRangeType, setAlternativeValue,
  setBoucleRangeType, setBoucleValue, setCode, setCommandement,
  setCondition, setContingent, setContingentMode, setcontingentValue,
  setDestination, setImperative,
  setIndications, setLineType, setModule, setNecessaryWhen, setPointToLine,
  setPointToMode, setTag, setTerme, setTitle, setTransition,
  setLinkLine, setLinkMode, addAttachment, setAttachment, removeAttachment
} = lineEditorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const lineEditorVisible = (state) => state.lineEditor.visible;
export const lineEditorLine = (state) => state.lineEditor.line;
export const lineEditorMode = (state) => state.lineEditor.mode;


export default lineEditorSlice.reducer;
