// Move out ?
const ASPECT_DURATIF = 'duratif';
const ASPECT_ITERATIF = 'itératif';
const ASPECT_SEMILFACTIF = 'sémelfactif';

function lineAspectLabel (aspect) {
  if (aspect == ASPECT_DURATIF) {
    return '→'
  }
  else if (aspect == ASPECT_ITERATIF) {
    return '…';
  }
  else if (aspect == ASPECT_SEMILFACTIF) {
    return '↓'
  }

  return null;
}

export function LineAspect ({ aspect }) {
  const label = lineAspectLabel(aspect);

  if (label) {
    return <span style={ { 'fontWeight': 'lighter' } }> { label }</span>
  }

  return <></>
}

export default LineAspect;