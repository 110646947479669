import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import scoreAPI from './scoreAPI';
import { FETCH_STATUSES, FETCH_STATUS_IDLE } from './constants';
import { deleteAttachmentOnRemote, duplicateAttachment, removeAttachment, removeManyAttachments, replaceAttachment, selectAttachment, selectAttachmentIdsToDelete, selectAttachmentsIdsToUpload, uploadAttachment, upsertManyAttachments } from './attachmentsSlice';
import { selectAllLineEntities, selectLineAndSublinesAsList } from './linesSlice';
import uuidv4 from '../../utils/uuid';

/* Score shape
  title: "Untitled",
  mainline: {}, // Line
  stage_set: "aucune",
  setting: '',  // String
  duration: '', // String
  genre: '',  // String
  tags: '', // String?
  is_editable: bool,
  score_type: 0,
  score_author: "",
  performance_author: "",
  presentation: "",
  effectif: "",
  language: "",
  permissions: {},
  shared_with: [],
  lines: {
    str:lineId: Line
  }
*/

// Utility function which transforms a map of lines: lines
// into a hierarchical structure.
const restructureLine = (lineId, lines, state) => ({
  ...lines[lineId],
  sublines: lines[lineId].sublines.map(lineId => restructureLine(lineId, lines, state)),
  attachments: lines[lineId].attachments.map(attachmentId => selectAttachment(state, attachmentId))
});

/* 
  Attachment Shape
  {
    id: null,  // null | string
    url: "",   // null | string
    title: "", // null | string
    attachment: null,
  }

*/
export const fetchScoreById = createAsyncThunk(
  'scores/fetchScoreByIdStatus',
  async (scoreId) => {
    const response = await scoreAPI.fetchScoreById(scoreId);
    return response;
  }
  // scoreSlice, attachmentsSlice, linesSlice
  // execute reducer when this call fulfills to extract their respective data
)

export const putScore = createAsyncThunk(
  'scores/putScore',
  async (_, { getState, dispatch, rejectWithValue }) => {
    // First upload attachments
    const attachmentIdsToDelete = selectAttachmentIdsToDelete(getState()),
          failedAttachmentDeletions = [];

    for (const attachmentId of attachmentIdsToDelete) {
      try {
        // Attachments should already have been removed from the attachments slice.
        // Just remove them on the remote.
        await dispatch(deleteAttachmentOnRemote(attachmentId));
      }
      catch (error) {
        failedAttachmentDeletions.push(attachmentId);
      }
    }

    const attachmentIdsToUpload = selectAttachmentsIdsToUpload(getState()),
          failedAttachmentUploads = [];
    
    for (let i=0; i < attachmentIdsToUpload.length; i++) {
      // Upload attachment. A new object will be created in the attachments slice
      // it should return the new id
      try {
        const uploadResult = await dispatch(uploadAttachment(attachmentIdsToUpload[i])).unwrap();
      }
      catch (error) {
        // Log error
        // Add to failed upload list, include it in the interface.
        failedAttachmentUploads.push(attachmentIdsToUpload[i]);
      }
    }
    
    if (failedAttachmentUploads.length > 0) {
      const attachmentNames = failedAttachmentUploads.map((id) => selectAttachment(getState(), id).filename);
      return rejectWithValue(`Saving failed. Could not upload some attachments (${ attachmentNames.join(', ') })`)
    }
    else {
      // Make a copy of the score which can be modified
      // and sent to the server
      const state = getState();
      const score = selectScore(state);
      let newScoreData = {
        ...score,
        attachments: score.attachments.map(attachmentId => selectAttachment(state, attachmentId)),
        mainline: restructureLine(score.mainline, selectAllLineEntities(state), state)
      };

      delete newScoreData.lines;
      
      return await scoreAPI.putScore(newScoreData)
        .then((d) => d)
        .catch(e => {
          return rejectWithValue(`Saving failed: ${ e }`)
        })
    }
  }
)


export const duplicateScore = createAsyncThunk(
  'scores/duplicateScore',
  async (_, { getState, dispatch, rejectWithValue }) => {
    // Make a copy of the score which can be modified
    // and sent to the server
    // Duplicate attachments
    // Create score
    const state = getState();
    const score = selectScore(state);
    const lines = selectLineAndSublinesAsList(state, score.mainline);
    const newIds = lines.map(() => uuidv4());
    const idTranslationMap = Object.fromEntries(newIds.map((newId, i) => [lines[i].id, newId]));
    // Construct map with new line ids and updated data of lines
    // where id's are translated using the translation map
    const newLines = Object.fromEntries(await Promise.all(lines.map(async (line, i) => ([
      newIds[i],
      {
        ...line,
        id: newIds[i],
        parentId: (line.parentId) ? idTranslationMap[line.parentId] : line.parentId,
        sublines: line.sublines.map(oldId => idTranslationMap[oldId]),
        pointTo: (line.pointTo && line.pointTo.line) ? { ...line.pointTo, line: idTranslationMap[lines.pointTo.line] } : line.pointTo,
        link: (line.link && line.link.line) ? { ...line.link, line: idTranslationMap[lines.link.line] } : line.link,
        attachments: await Promise.all(line.attachments.map(async (attachmentId) => await dispatch(duplicateAttachment(attachmentId)).unwrap()))
      }
    ]))));

    console.log('Reached line 135');

    // Change title to mark it is a clone
    
    let newScore = {
      ...score,
      title: score.title + ' (Copy)',
      mainline: restructureLine(idTranslationMap[score.mainline], newLines, getState())
    }

    delete newScore.lines;    
    delete newScore.id;

    return await scoreAPI.createScore(newScore)
      .then((d) => {
        dispatch(fetchScoreById(d.id));
        return true;
      })
      .catch(e => {
        return rejectWithValue(`Saving failed: ${ e }`)
      })
  }
)

export const createScore = createAsyncThunk(
  'scores/createScore',
  async (_, { getState, dispatch, rejectWithValue }) => {
    // First upload attachments
    const attachmentsIdsToUpload = selectAttachmentsIdsToUpload(getState()),
          failedAttachmentUploads = [];
    
    for (let i=0; i < attachmentsIdsToUpload.length; i++) {
      // Upload attachment. A new object will be created in the attachments slice
      // it should return the new id
      try {
        const uploadResult = await dispatch(duplicateAttachment(attachmentsIdsToUpload[i])).unwrap();
      }
      catch (error) {
        // Log error
        // Add to failed upload list, include it in the interface.
        failedAttachmentUploads.push(attachmentsIdsToUpload);
      }
    }
    
    if (failedAttachmentUploads.length > 0) {
      const attachmentNames = failedAttachmentUploads.map((id) => selectAttachment(getState(), id).filename);
      return rejectWithValue(`Saving failed. Could not upload some attachments (${ attachmentNames.join(', ') })`)
    }
    else {
      const state = getState();
      const lines = state.editor.present.lines.entities;

      // Make a copy of the score which can be modified
      // and sent to the server
      const score = {...state.editor.present.score.score};
      delete score.lines;

      
      score.mainline = restructureLine(score.mainline, lines, state);

      return await scoreAPI.createScore(score)
        .then((d) => d)
        .catch(e => {
          return rejectWithValue(`Saving failed: ${ e }`)
        })
    }
  }
)

export const setScoreMeta = createAsyncThunk('scores/setScoreMeta',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    
    const score = selectScore(getState()),
          newAttachments = payload.attachments,
          newAttachmentIds = new Set(newAttachments.map((attachment) => attachment.id)),
          // Find which attachment ids exist in the current value for attachments
          // but not in the new one. This are the attachments to delete.
          // currentAttachmentIds = ,
          attachmentIdsToRemove = new Set(score.attachments).difference(newAttachmentIds);
      
    // Remove removed attachments from attachment slice
    dispatch(removeManyAttachments([...attachmentIdsToRemove]));
    // Add new attachments
    dispatch(upsertManyAttachments(newAttachments));  

    // Upsert existing to update existing 
    return {
      ...payload,
      attachments: [...newAttachmentIds]
    }
  }
)

const initialState = {
  score: null,
  /** Index which holds whether lines are shown or not,  */
  expanded: [],
  fetch: {
    status: FETCH_STATUSES.IDLE,
    error: null
  },
  put: {
    status: FETCH_STATUSES.IDLE,
    error: null
  }
};


export const scoreSlice = createSlice({
  name: 'score',
  initialState,
  reducers: {
    // setScoreMeta: (state, action) => {
    //   // Remove old attachments
    //   // Fetch current attachments
    //   const newAttachments = action.payload.attachments,
    //         newAttachmentIds = newAttachments.map((attachment) => attachment.id),
    //         // Existing attachments should be list of id's
    //         attachmentIdsToRemove = new Set(state.score.attachments).difference(newAttachmentIds);
        
        
    //   // Upsert existing to update existing 
    //   state.score = {
    //     ...state.score,
    //     ...action.payload,
    //     attachments: attachments.map((attachment) => attachment.id)
    //   }
    // }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchScoreById.pending, (state, action) => {
        state.fetch.status = FETCH_STATUSES.PENDING;
      })
      .addCase(fetchScoreById.fulfilled, (state, action) => {
        state.fetch.status = FETCH_STATUSES.FULFILLED;
        // Omit lines and attachments from the score data
        const { lines, attachments, ...score } = action.payload;
        state.score = {
          ...score,
          attachments: attachments.map((attachment) => attachment.id)
        };
        state.expanded = Object.fromEntries(Object.keys(lines).map((id) => ([ id, false ])));
      })
      .addCase(fetchScoreById.rejected, (state, action) => {
        state.fetch.status = FETCH_STATUSES.FAILED;
        state.fetch.error = action.error.message;
      })
      .addCase(putScore.pending, (state, action) => {
        state.put.status = FETCH_STATUSES.PENDING;
        state.put.error = null;
      })
      .addCase(putScore.fulfilled, (state, action) => {
        state.put.status = FETCH_STATUSES.FULFILLED;
        state.put.error = null;
      })
      .addCase(putScore.rejected, (state, action) => {
        state.put.status = FETCH_STATUSES.FAILED;
        state.put.error = action.payload;
      })
      .addCase(createScore.pending, (state, action) => {
        state.put.status = FETCH_STATUSES.PENDING;
        state.put.error = null;
      })
      .addCase(createScore.fulfilled, (state, action) => {
        state.put.status = FETCH_STATUSES.FULFILLED;
        state.put.error = null;
        state.score.id = action.payload.id;
      })
      .addCase(createScore.rejected, (state, action) => {
        state.put.status = FETCH_STATUSES.FAILED;
        state.put.error = action.payload;
      })
      .addCase(setScoreMeta.fulfilled, (state, action) => {
        state.score = {
          ...state.score,
          ...action.payload
        }
      })
      .addCase(replaceAttachment, (state, action) => {
        const { attachmentId, newAttachment } = action.payload;

        if (state.score.attachments.indexOf(attachmentId) > -1) {
          state.score.attachments.splice(state.score.attachments.indexOf(attachmentId), 1, newAttachment.id);
        } 
      })
  }
});

export const selectScore = (state) => state.editor.present.score.score;

// export const { setScoreMeta } = scoreSlice.actions;

export default scoreSlice.reducer;