export function LineIndications ({ indications, commandement, destination, code }) {
  return <span className="line__indications">
      *
      <span className="label">
        { indications && <p>
            <span className="indications-label">
              Indications:
            </span>
            { indications.replace(/(?:\r\n|\r|\n)/g, '<br>') }
          </p> }
        
        { commandement && <p>
            <span className="commandement-label">
              Commandement:
            </span>
            { commandement }
          </p> }
      
        {/* { destination && <p>
          <span className="destination-label">
            Destination:
          </span>
          { destination }
        </p> } */}

        { code && <p>
            <span className="code-label">
              Code:
            </span>
            { code }
          </p> }
      </span>
  </span>
}

export default LineIndications;