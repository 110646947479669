import React from 'react';
import { translate as t } from '../../../utils/translate';
import HelpShortcut from '../../../utils/HelpShortcut';
import { useDispatch, useSelector } from 'react-redux';
import AttachmentField from './Attachment';
import { addAttachment as editorAddAttachment, removeAttachment as editorRemoveAttachment } from '../lineEditorSlice';
import { addAttachment, removeAttachment, selectManyAttachments, setAttachment} from '../attachmentsSlice'
import { newAttachment } from '../../../utils/attachment';

export default function AttachmentListField ({ attachmentIds }) {
  // loop through attachments
  // display thems
  // remove attachment link
  // add attachment link
  const dispatch = useDispatch(),
        attachments = useSelector(state => selectManyAttachments(state, attachmentIds));

  return <>
    { attachments.map(attachment => (<AttachmentField 
        key={ attachment.id } 
        attachment={ attachment }
        onChange={(attachment) => {
          dispatch(setAttachment(attachment))
        }} 
        onRemove={() => {
          dispatch(removeAttachment(attachment.id));
          dispatch(editorRemoveAttachment(attachment.id));
        }} />
    ))}
    <br/>
    <button onClick={ () => {
      const attachment = newAttachment()
      dispatch(addAttachment(attachment));
      dispatch(editorAddAttachment( attachment.id )) 
    } }>{ t('Ajouter une pièce jointe')}</button>
  </>

}