import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DRAG_ITEM_TYPES } from "./constants";
import { translate as t } from '../../utils/translate';
import { PlayerLine } from './Player';
import { RANGE_TYPES } from './constants';

// Render list of lines
// Internal events for reorder preview
// On reordering trigger the onchange event with new order.
// Can it be used in a class component. No.


function DraggablePlayerLine (props) {
  const dndRef = useRef(null);
  const { id, parent, onCancel, onDrop, onHover} = props;

  const [ { isDragging }, dragRef ] = useDrag(() => ({
    type: DRAG_ITEM_TYPES.PLAYER_LINE + `__${ parent }`,
    // Information on item being dragged
    item: { lineId: id },
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    isDragging: (monitor) => (monitor.getItem() && monitor.getItem().lineId === id),
    end: (item, monitor) => {
      const { lineId } = item,
            didDrop = monitor.didDrop();
      if (!didDrop) {
        onCancel();
      }
      else {
        onDrop();
      }
    }
  }), [ id  ])

  const [ { isOver }, dropRef ] = useDrop(() => ({
    accept: DRAG_ITEM_TYPES.PLAYER_LINE + `__${ parent }`,
    hover: (item, monitor) => {
      if (
        item.lineId !== id && dndRef.current && monitor.isOver({ shallow: true })
      ) {
        onHover(item.lineId, id);
        // dispatch(moveLinePreview({ lineId: lineId, insertionPointId: line.id }));
      }
    },
  }), [ id ])

  dragRef(dropRef(dndRef));

  return <div class="drag-wrapper" ref={ dndRef }>
    <PlayerLine { ...props } />
  </div>;
}


// /**
//  * This can be a class component.
//  * @param {*} param0 
//  */
// function OrderableSublines ({ order, onChange }) {
//   let [ order, setOrder] = useState(order);
//       [ preview, setIsDragging ] = useState()

//   order.forEach(id => {
//     <DraggableLine id={id} />
//   })
// }

export class EditableSublines extends React.Component {
  constructor (props) {
    super(props);
    const {
      parent, 
      lines,
      alternative,
      renderPath,
      orderable,
      selectable
    } = props;

    this.state = {
      parent,
      lines,
      lastOrder: lines,
      isDragging: false,
      renderPath,
      orderable,
      selectable,
      selected: [],
      minimum: this.minimum(alternative),
      maximum: this.maximum(alternative)
    }
  }

  confirmMove () {
    this.setState({
      ...this.state,
      isDragging: false,
      lastOrder: this.state.lines
    }, this.propagateOrderChange);
  }

  propagateOrderChange() {
    if (this.props.onOrderChange) {
      this.props.onOrderChange(this.state.lines)
    }
  }

  previewMove (draggedLineId, hoverLineId) {
    let newOrder = [ ...this.state.lines ];
    let oldPosition = this.state.lines.findIndex(lineId => lineId == draggedLineId);
    let newPosition = this.state.lines.findIndex(lineId => lineId == hoverLineId);
    newOrder.splice(oldPosition, 1);
    newOrder.splice(newPosition, 0, draggedLineId);

    this.setState({
      ...this.state,
      lines: newOrder,
      isDragging: true
    })
  }

  cancelMove () {
    this.setState({
      ...this.state,
      isDragging: false,
      lines: this.state.lastOrder
    }, this.propagateOrderChange);
  }

  minimum (alternative) {
    switch (alternative.type){
      case RANGE_TYPES.minimal:
        return alternative.value;
      case RANGE_TYPES.undetermined:
        return false;
      case RANGE_TYPES.exact:
        return alternative.value;
      case RANGE_TYPES.range:
        return alternative.value[0];
    }
  }
  
  maximum (alternative) {
    switch (alternative.type){
      case RANGE_TYPES.minimal:
        return false;
      case RANGE_TYPES.undetermined:
        return false;
      case RANGE_TYPES.exact:
        return alternative.value;
      case RANGE_TYPES.range:
        return alternative.value[1];
    }
  }
  
  propagateSelectionChange() {
    console.log('selection change')
    if (this.props.onSelectionChange) {
      const minimum = this.state.minimum,
            maximum = this.state.maximum,
            selectedCount = this.state.selected.length;
      const valid = (minimum === false || selectedCount >= minimum) && (maximum === false || selectedCount <= maximum);
      
      this.props.onSelectionChange(valid, this.state.selected);
    }
  }

  updateSelected (id, isSelected) {
    if (isSelected) {
      // Add to list of selected axes, if not already maximum of selected axes
      if (this.state.maximum === false || this.state.selected.length < this.state.maximum) {
        this.setState({
          ...this.state,
          selected: this.state.selected.concat([id])
        }, this.propagateSelectionChange);
      }
    }
    else {
      // Remove from list of selected axes
      this.setState({
        ...this.state,
        selected: this.state.selected.filter((v) => v != id)
      }, this.propagateSelectionChange);
    }
  }

  rangeLabel (alternative) {
    switch (alternative.type){
      case RANGE_TYPES.minimal:
        return `${alternative.value}+`;
      case RANGE_TYPES.undetermined:
        return '?';
      case RANGE_TYPES.exact:
        return alternative.value;
      case RANGE_TYPES.range:
        return `${alternative.value[0]}-${alternative.value[1]}`;
    }
  }



  render () {
    const { lines, parent, selected } = this.state;
    const { tag, transition, alternative, selectable, renderPath } = this.props; 
    let renderLines;

    if (this.state.orderable) {
      renderLines = lines.map((id, index) => (
        <DraggablePlayerLine
          id={ id }
          parent={ parent }
          index={ index }
          isLastLine={ index == lines.length - 1 }
          renderPath={ renderPath }
          // Call back when a line is dropped
          // Confirms a new state
          onDrop={ () => this.confirmMove() }
          // Call back on a hover, change line order
          // to preview, but do not confirm
          onHover={ (draggedLineId, hoverLineId) => this.previewMove(draggedLineId, hoverLineId) }
          // Rewind to last 'confirmed' state
          onCancel={ () => this.cancelMove() }
          selectable={ selectable }
          selected={ (selected.indexOf(id) > -1) }
          onChange={ (isSelected) => this.updateSelected(id, isSelected) } />
      ))
    }
    else {
      renderLines = lines.map((id, index) => (
        <PlayerLine
          id={ id }
          index={ index }
          isLastLine={ index == lines.length - 1 }
          renderPath={ renderPath }
          // Call back when a line is dropped
          // Confirms a new state
          selectable={ selectable }
          selected={ (selected.indexOf(id) > -1) }
          onChange={ (isSelected) => this.updateSelected(id, isSelected) } />
      ))
    }
    return <section className="sublines">
      <section className="sublines__metadata__wrapper">
        <section className="sublines__metadata">
          { tag && <span className="tag" data-tag={ tag } /> }
          { transition && <span className="transition" label={ t(transition) } data-transition={ transition } /> }
          { alternative && <span className="alternative">
            {/* { this.state.selected.length } / */}
            { this.rangeLabel(alternative)}
          </span> }
        </section>
      </section>
      { renderLines }
    </section>
  }
}