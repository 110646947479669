import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';
import userApi from './userApi';

/**
 * For searching users
 */

const usersAdapter = createEntityAdapter();

export const fetchUsersByName = createAsyncThunk(
  'users/fetchUsersByStatus',
  async (query) => {
    const response = await userApi.fetchUsersByName(query);
    return response;
  }
)

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(),
  reducers: {
    clearFoundUsers: (state, action) => usersAdapter.removeAll(state)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersByName.fulfilled, (state, action) => {
        // On fetch remove all users. Add found ones.
        usersAdapter.removeAll(state);
        usersAdapter.addMany(state, action.payload);
      })
  }
});

// const simpleSelectors = usersAdapter.getSelectors()
const globalizedSelectors = usersAdapter.getSelectors((state) => state.users)

export const selectFoundUsers = globalizedSelectors.selectAll;

export const { clearFoundUsers } = usersSlice.actions;

export default usersSlice.reducer;