import { FETCH_STATUSES, FETCH_STATUS_IDLE } from './constants';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import scoreAPI from './scoreAPI';

/**
 * @TODO rename to scores ?
 * Needs a 'partial'-loaded status
 */

export const fetchScoreList = createAsyncThunk(
  'scoreList/fetchScoreListStatus',
  async () => {
    const response = await scoreAPI.fetchScoreList()  ;
    return response;
  }
)

const scoresAdapater = createEntityAdapter({})

export const scoreListSlice = createSlice({
  name: 'scoreList',
  initialState: scoresAdapater.getInitialState({
    status: FETCH_STATUSES.IDLE,
    error: ''
  }),
  reducers: { },
  extraReducers(builder) {
    builder
      .addCase(fetchScoreList.pending, (state, action) => {
        state.status = FETCH_STATUSES.PENDING
      })
      .addCase(fetchScoreList.fulfilled, (state, action) => {
        state.status = FETCH_STATUSES.FULFILLED
        scoresAdapater.addMany(state, action.payload);
      })
      .addCase(fetchScoreList.rejected, (state, action) => {
        state.status = FETCH_STATUSES.FAILED
        state.error = action.error.message
      })
  }
});

const globalizedSelectors = scoresAdapater.getSelectors((state) => state.scoreList)

export const selectScores = globalizedSelectors.selectAll;

export default scoreListSlice.reducer;