// https://stackoverflow.com/a/5100158
export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  // Data uri looks like: 'data:image/png;base64,iVBORw0…FTkSuQmCC'
  if (dataURI.startsWith('data:')) {
    let [head, body] = dataURI.split(',', 2);
    // Cut off data:
    head = head.substring(5);

    let mimeString, byteString;
    if (head.indexOf('base64') >= 0) {
      // Decode content, extract mime-type from head
      byteString = atob(body);
      mimeString = head.split(';')[0];
    }
    else {
      // Decode content, what is left in the head is considered as the mime-type
      byteString = decodeURIComponent(body);
      mimeString = head;
    }

    let ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the bytes of the string to a typed array
    return new Blob([ia], { type: mimeString });
  }

  return null;
}