import React from 'react';
import { translate as t } from '../../../utils/translate';
import HelpShortcut from '../../../utils/HelpShortcut';
import { RANGE_TYPES } from '../constants';
import { useDispatch } from 'react-redux';
import { disableBoucle, enableBoucle, setBoucleRangeType, setBoucleValue } from '../lineEditorSlice';

export default function LoopField ({ loop }) {
  const dispatch = useDispatch();

  return <section className="fieldgroup">
    <label className="inline">
      <HelpShortcut target="#boucle">{ t('Boucle') }</HelpShortcut>
      <input type="checkbox" name="toggle-range-field" checked={ (loop) ? true : false } onChange={ (e) => dispatch((e.target.checked) ? enableBoucle() : disableBoucle()) }/>
    </label>
    {loop && <>
      <label className="inline">
        { t('effectuer l’axe') }
        <select name="type" value={ (loop) ? loop.type : loop } onChange={ (e) => { dispatch(setBoucleRangeType(e.target.value)) } }>
          <option value={ RANGE_TYPES.undetermined }>
            { t('indéterminé') }
          </option>
          <option value={ RANGE_TYPES.exact } >
            { t('exactement') }
          </option>
          <option value={ RANGE_TYPES.minimal } >
            { t('au moins') }
          </option>
          <option value={ RANGE_TYPES.range } >
            { t('entre') }
          </option>
        </select>
      </label>
      { loop && loop.type == RANGE_TYPES.exact && 
        <section data-range-type="<%= rangeType.exact %>" className="type-fields inline">
          <input type="number" name="exact" min="2" className="small-number" value={ loop.value } onChange={ (e) => { dispatch(setBoucleValue(parseInt(e.target.value))) } } />
        </section> }
      { loop && loop.type == RANGE_TYPES.minimal && 
        <section data-range-type="<%= rangeType.minimal %>" className="type-fields inline">
          <input type="number" name="minimal" min="1" className="small-number" value={ loop.value } onChange={ (e) => { dispatch(setBoucleValue(parseInt(e.target.value))) } } />
        </section> }
      { loop && loop.type == RANGE_TYPES.range && 
        <section data-range-type="<%= rangeType.range %>" className="type-fields inline">
          <input type="number" name="range-min" min="1" className="small-number" value={ loop.value[0] } onChange={ (e) => { dispatch(setBoucleValue([ parseInt(e.target.value), loop.value[1] ])) } } />
          { t('et') }
          <input type="number" name="range-max" min="2" className="small-number" value={ loop.value[1] } onChange={ (e) => { dispatch(setBoucleValue([ loop.value[0], parseInt(e.target.value) ])) } } />
        </section> }
      { t('fois') }
    </> }
  </section>
}