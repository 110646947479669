import { createSlice, createEntityAdapter, createSelector, current } from '@reduxjs/toolkit';
import { fetchScoreById } from './scoreSlice';
import { makeNewLine } from '../../utils/line';
import { translate as t } from '../../utils/translate';
import { format as f } from '../../utils/format';
import { CONTINGENT_DEFAULT, LINE_TYPE_REGULAR, LINE_TYPE_LINK, TAG_SUCCESSIVE, TRANSITION_DEFAULT, TAG_DEFAULT } from './constants';
import { addAttachment, replaceAttachment, uploadAttachment } from './attachmentsSlice';

const linesAdapter = createEntityAdapter();

const INSERT_BEFORE = 'insert_before';
const INSERT_AFTER = 'insert_after';

/*
  Line Shape

  // Lines can have two types `regular` and `linked`
  // Linked lines habe a value set for the link field of type Link
  // 
  // Regular lines can point to lines.

  // An id is necessary to make links to axes before they are saved.
  // Once models have an id they are managed by the collection.
  id: uuidv4(),
  type: enum { normal, related }
  title: t('axe principal'), //(uniq)
  contingent: boolean, // optionnel, au choix de l'interprète | { mode: null | 'condition' | 'obligation' | 'imperatif, value: str | null }
  necessary_when: str | null,
  module_: false,
  aspect: '',
    //choix dans un array (duratif, itératif, sémelfactif)
    // duratif, l'action se déroule en continu
    // itératif, l'action est répétitive
    // sémelfactif l'action est ponctuelle
  piece_jointe: '', //url qui pointe vers un document
  terme: '', //(str)
  attachment: null, // Attachment
  attachments: [
    Attachment,
    ...
  ]

  // boucle: on teste d'abord si boucle check est true
  // si oui y a t'il une durée de n à p
  // si non mettre n et p à 0
  // TODO: check if those 4 props can be merge in one

  // rangeType = enum{undetermined, exact, minimal, range}
  // null | { type: rangeType, value: null|number|[number, number]}
  boucle: null,

  // TODO: check if those 3 props can be merge in one
  // alternativeMode = enum { inclusive, exclusive}
  // null | { type: rangeType, mode: alternativeMode, value: null|number|[number, number]}
  alternative: null,
  // alternative_mode: '', //choix dans un array (exclusive, inclusive, conditionnelle) Validation importante des conditions dans une boucle inclusive
  // alternative_symbole: '',

  condition: '', // seulement valide si axe contingent ou si sous-axe d'une alternative
  imperative: false, // Boolean
  tag: '', //Validation: peut avoir un tag seulement si l'axe      n'est pas principal, de plus le tag doit être le même pour ses siblings. Succession ordonnée, sans ordre, simultaméité, accumulation
  goto: null, //reference de l'axe vers lequel il renvoie
  pointTo: null | { line: null | string, mode: POINT_TO_MODE },
  focused: 0, // pas sûr que c'est l'endroit où mettre ceci.
  // Pas vraiment une propriété de l'objet.
  // Si l'objet est focused, il faut que la classe axis-focus
  // soit sur ses éléments handle et options.
  parent: null,
  parentId: null, // int containing parent id
  sublines: [], // array<str:id> array containing lineId's of sublines
  actant: '', // string
  adresse: '', // string
  commandement: '', // string
  destination: '', // string
  code: '', // string
  indications: '' // long string,
  link: null | { line: null | string, mode: LINK_MODE }
  transition: enum { chained, crossfade, cut }
*/

// Helper for reducers, executing common tasks
const reducerHelpers = {
  /**
   * Moves line to new position, and removes it from current parent.
   * @param {*} state 
   * @param {string} lineId Id of line to move
   * @param {string} newParentId Id of parent to move the line to
   * @param {int} index Position in parent
   */
  moveLine: (state, lineId, newParentId, index) => {
    const line = state.entities[lineId],
          newParent = state.entities[newParentId],
          currentParent = state.entities[line.parentId];

    currentParent.sublines.splice(currentParent.sublines.indexOf(lineId), 1);
    line.parentId = newParentId;
    // Take on props which are shared by siblings
    line.tag = newParent.sublines[0].tag;
    line.transition = newParent.sublines[0].transition;
    newParent.sublines.splice(index, 0, lineId);
  },


  // Returns all the parent ids, including the id of the line itself
  allParentIds: (state, lineId) => {
    const line = state.entities[lineId];

    if (line.parentId) {
      return [ lineId ].concat(reducerHelpers.allParentIds(state, line.parentId));
    }
    else {
      return [ lineId ];
    }
  },

  /**
   * Derives a list of sublines to this line.
   * @param {*} state 
   * @param {string} lineId lineId
   * @returns Array<string> list of line ids
   */
  getAllSublineIds: (state, lineId) => {
    /* Visit all sublines */
    const reducer = (lineId) => [ lineId ].concat(state.entities[lineId].sublines.flatMap(reducer))
    return state.entities[lineId].sublines.flatMap(reducer)
  },

  findFirstCommonEntry: (listA, listB) => {
    // Return first entry in listA which also occurs in other listB
    return listA.find((entry) => (listB.indexOf(entry) > -1));
  },

  getSiblingIds: (state, lineId) => {
    const line = state.entities[lineId];
    if (line.parentId) {
      const parent = state.entities[line.parentId];
      
      return parent.sublines.filter(siblingId => siblingId != lineId);
    }

    return [];
  },

  indexOfLineInParent: (state, lineId) => {
    const line = state.entities[lineId];

    if (line.parentId) {
      return state.entities[line.parentId].sublines.indexOf(lineId);
    }

    return undefined;
  },

  /**
   * Tries to determine where the lines occur in the score.
   * 
   * Traverses through the parents of both lines and takes first
   * shared id: common parent. Then compares the position of the
   * parent which is a child of the common parent.
   * 
   * @param {*} state 
   * @param {*} lineId 
   * @param {*} otherLineId 
   * @returns 
   */
  getRelativeIndexes: (state, lineId, otherLineId) => {
    const parentIds = reducerHelpers.allParentIds(state, lineId),
          otherParentIds = reducerHelpers.allParentIds(state, otherLineId),
          firstCommonParent = reducerHelpers.findFirstCommonEntry(parentIds, otherParentIds),
          firstCommonParentIndexes = [
            parentIds.indexOf(firstCommonParent),
            otherParentIds.indexOf(firstCommonParent)
          ];

    if (firstCommonParentIndexes[1] == 0) {
      // Edge case where the a line is dragged on the parent of its parent.
      // This can happen when the line was first marked as a new subline.
      // return twice the same index.
      return [
        reducerHelpers.indexOfLineInParent(state, otherParentIds[0]),
        reducerHelpers.indexOfLineInParent(state, otherParentIds[0])
      ]
    }

    // Traverse through the parents of both lines. 
    // Get index of line just below first common parent
    // Parents is a list of Id's. 
    // The first element is the original line. The last element is always the main line.
    return [
      reducerHelpers.indexOfLineInParent(state, parentIds[firstCommonParentIndexes[0] - 1]),
      reducerHelpers.indexOfLineInParent(state, otherParentIds[firstCommonParentIndexes[1] - 1])
    ];
  },
}

const adapterSelectors = linesAdapter.getSelectors();

const localSelectors = {
  selectLine: (state, id) => adapterSelectors.selectById(state, id),

  selectAllLines: (state) => adapterSelectors.selectAll(state),

  selectAllLineEntities: (state) => adapterSelectors.selectEntities(state),

  // Candidates to memoize?
  // Object.values, because lines is a dict, want entries, not keys
  selectLinesWithPointTo: (state) => Object.values(state.entities).filter((line) => (line.type == LINE_TYPE_REGULAR && !!line.pointTo?.line)),

  selectLinkedLines: (state) => Object.values(state.entities).filter((line) => (line.type == LINE_TYPE_LINK && !!line.link?.line)),

  /**
   * Select line connectors, can be point to, as well as a linked line
   * @param {object} state current state
   */
  selectConnectors: (state) => ([
    ...localSelectors.selectLinkedLines(state).map(line => ({ from: line.id, to: line.link.line, type: `link--${ line.link.mode }`, })),
    ...localSelectors.selectLinesWithPointTo(state).map(line => ({ from: line.id, to: line.pointTo.line, type: `pointTo--${ line.pointTo.mode }` }))
  ]),
  
  selectLineAndAncestors: (state, lineId) => {
    const tree = [];

    if (lineId) {
      do {
        tree.push(state.entities[lineId]);
        lineId = state.entities[lineId].parentId;
      } while (lineId);
    }
    
    return tree;
  },


  /**
   * Get line and all its sublines as a list of tuples, where the first entry is the level
   * of the line and the second the line
   * 
   * Probably returns new references because of flatMap
   */
  selectLinesAndSublinesAsListWithLevels: (state, lineId) => {
    const recursive_selector = (state, lineId, level) => (
      [ [ level, state.entities[lineId] ] ]
        .concat(
          state.entities[lineId]
            .sublines.flatMap((subLineId) => recursive_selector(state, subLineId, level + 1))));
  
    return recursive_selector(state, lineId, 0);
  },

  selectLineAndSublinesAsList: (state, lineId) => {
    const recursive_selector = (state, lineId) => (
      [ state.entities[lineId] ]
        .concat(
          state.entities[lineId]
            .sublines.flatMap((subLineId) => recursive_selector(state, subLineId)))
    );

    return recursive_selector(state, lineId);
  },

  selectLineDepth: (state, lineId) => {
    if (lineId) {
      const recursive_selector = (state, lineId) => {
        const line = state.entities[lineId];
  
        if (line.sublines && line.sublines.length > 0) {
          return Math.max.apply(this, line.sublines.map((id) => recursive_selector(state, id))) + 1;
        }
        else {
          return 1;
        }
      }
  
      return recursive_selector(state, lineId);
    }

    return null;
  }
}

export const linesSlice = createSlice({
  name: 'lines',
  initialState: linesAdapter.getInitialState(),
  reducers: {
    addLine: linesAdapter.addOne,
    addLines: linesAdapter.addMany,
    /**
     * Updates line.
     * Can insert sublines on changes to alternative.
     * @param {*} state 
     * @param {*} action { payload: Line }
     */
    setLine: (state, action)=> {
      const oldAlternative = state.entities[action.payload.id].alternative;
      linesAdapter.setOne(state, action.payload);
      /*
        If an axis is saved, check whether there are changes to the 'alternative' field.
        If so: ensure there are at least two sub-axes, that these sub-axes are contingent.
      */
      const line = state.entities[action.payload.id];
      if (line.alternative && !oldAlternative) {
        /* Ensure there are enough sublines */
        while (state.entities[action.payload.id].sublines.length < 2) {
          let newLine = makeNewLine({ 
            title: f(t('sous-axe {}', state), state.entities[action.payload.id].sublines.length + 1),
            parentId: state.entities[action.payload.id].id,
            tag: action.payload.tag,
            transition: action.payload.transition,
            contingent: CONTINGENT_DEFAULT
          });
          linesAdapter.addOne(state, newLine);
          linesAdapter.upsertOne(state, {
            id: action.payload.id,
            sublines: state.entities[action.payload.id].sublines.concat(newLine.id),
          });
        }
        line.sublines.forEach(id => {
          if (!state.entities[id].contingent) {
            linesAdapter.upsertOne(state, {
              id: id,
              contingent: CONTINGENT_DEFAULT
            });
          }
        })
      }
      const siblingIds = reducerHelpers.getSiblingIds(state, action.payload.id);
      // Propagate tag and transition to siblings
      linesAdapter.upsertMany(state, siblingIds.map(id => ({ 
        id: id,
        tag: action.payload.tag,
        transition: action.payload.transition 
      })));
    },
    /**
     * 
     * @param {*} state 
     * @param {*} action { payload: LineId:int }
     */
    removeLine: (state, action) => {
      // Todo add complication of removing subchildren
      const lineId = action.payload,
            line = state.entities[lineId],
            idsToRemove = [ lineId ].concat(reducerHelpers.getAllSublineIds(state, lineId));
  
      if (line.parentId) {
        const parent = state.entities[line.parentId];
        parent.sublines.splice(parent.sublines.indexOf(lineId), 1);
      }

      linesAdapter.removeMany(state, idsToRemove);
    },

    moveLineConfirm: (state, action) => {
      // No-op action, the preview already reorganized the state.
      // Can leave as is.
    },
    moveLineCancel: (state, action) => {
      const { lineId, originalParentId, originalIndex } = action.payload;
      reducerHelpers.moveLine(state, lineId, originalParentId, originalIndex);
    },
  
    /**
     * payload: {
     *  lineId: string // id of the line to move,
     *  insertionPointId: string // id of the line to insert after
     * }
     */
    moveLinePreview: (state, action) => {
      const { lineId, insertionPointId, asChild } = action.payload,
            line = state.entities[lineId],
            parentId = line.parentId,
            parent = state.entities[parentId],
            insertionPointLine = state.entities[insertionPointId],
            newParentId = (asChild) ? insertionPointId : insertionPointLine.parentId,
            insertionPointParents = reducerHelpers.allParentIds(state, insertionPointId),
            insertionPointParent = state.entities[insertionPointParents[1]],
            oldPosition = parent.sublines.indexOf(lineId),
            newPosition = insertionPointParent.sublines.indexOf(insertionPointId);
  
      if (insertionPointParents.indexOf(lineId) < 0) {
        // Ensure the line is not one of the parents of the insertion point
        
        if (asChild) {
          parent.sublines.splice(oldPosition, 1);
          insertionPointLine.sublines.push(lineId);
        }
        else if (parentId === newParentId) {
          // Line is moving withinin the same parent.
          parent.sublines.splice(oldPosition, 1);
          parent.sublines.splice(newPosition, 0, lineId);
        } 
        else {
          let moveMode;
          
          // Take on props which are shared by siblings from new sibling 
          line.tag = insertionPointLine.tag;
          line.transition = insertionPointLine.transition;

          if (insertionPointId === parentId) {
            // The line is moved on top of it's parent. It's moving upward
            // therefor insert before
            moveMode = INSERT_BEFORE;
          }
          else {
            const [ indexOldPosition, newIndex ] = reducerHelpers.getRelativeIndexes(state, lineId, insertionPointId);
            if (indexOldPosition <= newIndex) {
              moveMode = INSERT_BEFORE;
            }
            else {
              moveMode = INSERT_AFTER;
            }
          }

          parent.sublines.splice(oldPosition, 1);

          if (moveMode == INSERT_AFTER) {
            insertionPointParent.sublines.splice(newPosition + 1, 0, lineId);
          }
          else {
            insertionPointParent.sublines.splice(newPosition, 0, lineId);
          }
        }
  
        line.parentId = newParentId;
      }
    },
    addSubline: (state, action) => {
      const { lineId } = action.payload,
            line = state.entities[lineId],
            tag = (line.sublines.length > 0) ? state.entities[line.sublines[0]].tag : TAG_DEFAULT,
            transition = (line.sublines.length > 0) ? state.entities[line.sublines[0]].transition : TRANSITION_DEFAULT;

      // Do it twice if there isn't a subline yet
      do {
        let newLine = makeNewLine({ 
          title: f(t('sous-axe {}', state), line.sublines.length + 1),
          parentId: line.id,
          tag: tag,
          transition: transition
        });
        linesAdapter.addOne(state, newLine)
        line.sublines.push(newLine.id);
      }
      while (line.sublines.length < 2)
    },
    // /*
    //  * When an Attachment is created on the server it gets a new id
    //  * swap out old and new attachment object / reference
    // */
    // replaceAttachmentId: (state, action) => {
    //   const { oldAttachmentId, newAttachmentId } = action.payload;

    //   state.ids.forEach(lineId => {
    //     const line = state.entities[lineId];
    //     if (line.attachments.indexOf(oldAttachmentId) > -1) {
    //       line.attachments.splice(line.attachments.indexOf(oldAttachmentId), 1, newAttachmentId);
    //       // line.attachments = line.attachments.map(attachmentId => (attachmentId === oldAttachmentId) ? newAttachmentId : attachmentId);
    //     } 
    //   });
    // },
    // setNewLineIds: (state, action) => {
    //   const lines = localSelectors.selectAllLines(state);
    //   const newIds = lines.map((line) => uuidv4());
    //   const idTranslationMap = new Map(newIds.map((newId, i) => [lines[i].id, newId]));
    //   // Update all lines to take on new ID an update references to other lines
    //   linesAdapter.addMany(lines.map((line, i) => ({
    //     ...line,
    //     id: newIds[i],
    //     parentId: (line.parentId) ? idTranslationMap(line.parentId) : line.parentId,
    //     sublines: line.sublines.map(oldId => idTranslationMap[oldId]),
    //     pointTo: (line.pointTo && line.pointTo.line) ? { ...line.pointTo, line: idTranslationMap[lines.pointTo.line] } : line.pointTo,
    //     link: (line.link && line.link.line) ? { ...line.link, line: idTranslationMap[lines.link.line] } : line.link
    //   })));
    //   // Remove old lines
    //   linesAdapter.removeMany(Object.keys(idTranslationMap));
    // }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchScoreById.fulfilled, (state, action) => {
        // Adds line entities to the slice
        // when the line is inserted only the id is kept.
        linesAdapter.addMany(state, Object.values(action.payload.lines).map((line) => ({ ...line, attachments: line.attachments.map((line) => line.id) })))
      })
      .addCase(replaceAttachment, (state, action) => {
        const { attachmentId, newAttachment } = action.payload;

        state.ids.forEach(lineId => {
          const line = state.entities[lineId];
          if (line.attachments.indexOf(attachmentId) > -1) {
            line.attachments.splice(line.attachments.indexOf(attachmentId), 1, newAttachment.id);
            // line.attachments = line.attachments.map(attachmentId => (attachmentId === oldAttachmentId) ? newAttachmentId : attachmentId);
          } 
        });
      })
  }
});

/** Adapter for selector state */
const adaptedSelector = (selector) => (state, ...args) => selector(state.editor.present.lines, ...args)

/** Globalized selectors for export */
export const selectLine = adaptedSelector(localSelectors.selectLine);

export const selectAllLines = adaptedSelector(localSelectors.selectAllLines);

export const selectAllLineEntities = adaptedSelector(localSelectors.selectAllLineEntities);

export const selectConnectors = adaptedSelector(localSelectors.selectConnectors);

export const selectLineAndAncestors = adaptedSelector(localSelectors.selectLineAndAncestors);

// export const selectScoreLines = adaptedSelector(localSelectors.selectScoreLines);

export const selectLineAndSublinesAsList = adaptedSelector(localSelectors.selectLineAndSublinesAsList);

export const selectLinesAndSublinesAsListWithLevels = adaptedSelector(localSelectors.selectLinesAndSublinesAsListWithLevels);

export const selectLineDepth = adaptedSelector(localSelectors.selectLineDepth);

/**
 * Returns a list of ids of given line and all its sublines
 */
export const selectLineAndSublinesIdsAsList = createSelector(
  adaptedSelector(localSelectors.selectLineAndSublinesAsList),
  ((lines) => lines.map((line) => line.id))
)

// export const moveLinePreview = adaptedSelector(localSelectors.moveLinePreview);

// export const getSelectLine = () => selectLine;

export const { addSubline, moveLineCancel, moveLineConfirm, moveLinePreview, removeLine, setLine } = linesSlice.actions;

export default linesSlice.reducer;