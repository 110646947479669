import React from 'react';
import { translate as t } from '../../../utils/translate';
import HelpShortcut from '../../../utils/HelpShortcut';
import { POINT_TO_MODES, INDENT } from '../constants';
import { selectLinesAndSublinesAsListWithLevels } from '../linesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { disablePointTo, enablePointTo, setPointToLine, setPointToMode } from '../lineEditorSlice';
import { selectScore } from '../scoreSlice';

/*

  PointTo field has two modes:
  - feeds
  - goto

*/
// Old version which supports multiple pointTo mode, for now only feeds
// @FIXME: if it stays like this, simplify in the future?
// export function PointToField ({ pointTo }) {
//   const dispatch = useDispatch(),
//         lines = useSelector(selectLinesAsList);
//   return <>
//     <label className="inline">
//       <HelpShortcut target="#goto">{ t('PointTo') }</HelpShortcut>
//       <input type="checkbox" name="toggle-pointto-field" checked={ (pointTo) ? true : false } onChange={ (e) => dispatch((e.target.checked) ? enablePointTo() : disablePointTo()) } />
//     </label>
//     { pointTo && <>
//       <label className="inline">
//         { t('choisir') }
//         <select name="type" value={ (pointTo) ? pointTo.mode : pointTo } onChange={ (e) => dispatch(setPointToMode(e.target.value)) }>
//           <option value={ POINT_TO_MODES.GOTO } >
//             { t('goto') }
//           </option>
//           <option value={ POINT_TO_MODES.FEEDS } >
//             { t('feeds') }
//           </option>
//         </select>
//       </label>
//       <label className="inline">
//         { t('axe') }
//         <select name="line" value={ (pointTo) ? pointTo.line : pointTo } onChange={ (e) => dispatch(setPointToLine(e.target.value)) }>
//           { lines.map(([ level, line ]) => <option key={ line.id } id={ line.id } value={ line.id }>{ INDENT.repeat(level) + line.title }</option> ) }
//         </select>
//       </label>
//     </> }
//   </>;
// }


export function PointToField ({ pointTo }) {
  const dispatch = useDispatch(),
        score = useSelector(selectScore),
        lines = useSelector((state) => selectLinesAndSublinesAsListWithLevels(state, score.mainline));
  return <>
    <label className="inline">
      <HelpShortcut target="#feeds">{ t('Feeds') }</HelpShortcut>
      <input type="checkbox" name="toggle-pointto-field" checked={ (pointTo) ? true : false } onChange={ (e) => dispatch((e.target.checked) ? enablePointTo() : disablePointTo()) } />
    </label>
    { pointTo && <>
      <label className="inline">
        { t('axe') }
        <select name="line" value={ (pointTo) ? pointTo.line : pointTo } onChange={ (e) => dispatch(setPointToLine(e.target.value)) }>
          { lines.map(([ level, line ]) => <option key={ line.id } id={ line.id } value={ line.id }>{ INDENT.repeat(level) + line.title }</option> ) }
        </select>
      </label>
    </> }
  </>;
}


export default PointToField