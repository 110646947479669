import { APIBaseUrl } from '../organon/settings';


export function whoami () {
  return new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/api/users/whoami/`, { 'method': 'GET', 'credentials': 'include' })
      .then(r => {
        if (r.status === 200) {
          r.json()
            .then(data => resolve(data))
            .catch(reject);
        }
        else if (r.status === 401) {
          resolve(null);
        }
        else {
          reject(r.status);
        }
      })
      .catch(reject);
  }) 
}


export function fetchUsersByName (query) {
  const promise = new Promise((resolve, reject) => {
    fetch(`${APIBaseUrl}/api/users/?search=${query}`, { 'method': 'GET', 'credentials': 'include' })
      .then(r => {
        r.json()
          .then(data => resolve(data))
          .catch(reject);
      })
      .catch(reject)
  });


  return promise;
}


export default { fetchUsersByName, whoami }