import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_LANGUAGE, FETCH_STATUSES } from './constants';
import { getTranslationsState, selectTranslation } from './translationsSlice';

export function LanguageSelector () {
  const selectedLanguage = useSelector(getTranslationsState).selectedLanguage,
        status = useSelector(getTranslationsState).status,
        dispatch = useDispatch();

  useEffect(() => {
    if (status === FETCH_STATUSES.IDLE)  {
      dispatch(selectTranslation(DEFAULT_LANGUAGE));
    }
  }, [status, dispatch]);
      
  return <div className="languageSelector">
    <span onClick={ () => dispatch(selectTranslation('fr')) }>
      { (selectedLanguage == 'fr') && '[x]'} FR
    </span>
    -
    <span onClick={ () => dispatch(selectTranslation('en')) }>
      { (selectedLanguage == 'en') && '[x]'} EN
    </span>
  </div>
}