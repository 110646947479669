import uuidv4 from './uuid'

import { FETCH_STATUSES } from '../features/score/constants'

export function newAttachment () {
  return {
    id: uuidv4(),  // null | string
    title: null, // null | string,
    type: null, // null | string
    url: null,   // null | string
    state: FETCH_STATUSES.IDLE
  }
}