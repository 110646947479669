import React from 'react';
import { RANGE_TYPES } from '../constants';

export class LineRepeatInlineInput extends React.Component {
  constructor(props) {
    super(props);
    const { repeat, onChange } = props;

    this.state = {
      repeat,
      onChange,
      minimum: this.minimum(repeat),
      maximum: this.maximum(repeat),
      value: Math.max(0, parseInt(this.minimum(repeat)))
    }

    this.propagateChange();
  }

  minimum (repeat) {
    switch (repeat.type){
      case RANGE_TYPES.minimal:
        return repeat.value;
      case RANGE_TYPES.undetermined:
        return 1;
      case RANGE_TYPES.exact:
        return repeat.value;
      case RANGE_TYPES.range:
        return repeat.value[0];
    }
  }
  
  maximum (repeat) {
    switch (repeat.type){
      case RANGE_TYPES.minimal:
        return false;
      case RANGE_TYPES.undetermined:
        return false;
      case RANGE_TYPES.exact:
        return repeat.value;
      case RANGE_TYPES.range:
        return repeat.value[1];
    }
  }

  set (value) {
    this.setState({
      ...this.state,
      value: parseInt(value)
    }, this.propagateChange);
  }

  propagateChange() {
    if (this.props.onChange) {
      const minimum = this.state.minimum,
            maximum = this.state.maximum,
            value = this.state.value;
      const valid = (minimum === false || value >= minimum) && (maximum === false || value <= maximum);

      this.props.onChange(valid, value);
    }
  }

  rangeLabel (repeat) {
    switch (repeat.type){
      case RANGE_TYPES.minimal:
        return `${repeat.value}+`;
      case RANGE_TYPES.undetermined:
        return '?';
      case RANGE_TYPES.exact:
        return repeat.value;
      case RANGE_TYPES.range:
        return `${repeat.value[0]}-${repeat.value[1]}`;
    }
  }

  render () {
    const { repeat, value, minimum, maximum } = this.state;

    return <span className="boucle option">
      <input autoFocus={ this.props.autoFocus } type="number" size="3" min={ minimum } max={ maximum } value={ value } onChange={ (e) => this.set(e.target.value) } /> {this.rangeLabel(repeat)}
    </span>
  }
}

//{ boucle.type == RANGE_TYPES.minimal && <><input type="number" size="4" min={ boucle.value } defaultValue={ boucle.value }/> ({ boucle.value }+)</> }
//{ boucle.type == RANGE_TYPES.range && <><input type="number" size="4" min={ boucle.value[0] } max={ boucle.value[1] } defaultValue={ boucle.value }/> { boucle.value[0] }-{ boucle.value[1] }</> }

export default LineRepeatInlineInput;