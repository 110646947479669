import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchScoreById } from '../score/scoreSlice';
import { FETCH_STATUSES } from '../score/constants';
import userApi from '../score/userApi';

export const fetchCurrentUser = createAsyncThunk(
  'organon/fetchCurrentUser',
  async () => {
    const response = await userApi.whoami();
    return response;
  }
)

const initialState = {
  // hack, hack, let's put some tape
  // Depending on the location define initial state to display the score
  // or the index. Needs to be replaced with proper routing.
  panel: document.location.pathname.startsWith('/score/') ? 'score' : 'index', // index || score
  user: {
    user: null,
    status: FETCH_STATUSES.IDLE
  }
};

export const organonSlice = createSlice({
  name: 'organon',
  initialState,
  reducers: {
    setPanel: (state, action) => {
      state.panel = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScoreById.pending, (state, action) => {
      state.panel = 'score'
    });
    builder.addCase(fetchCurrentUser.pending, (state, action) => {
      state.user.state = FETCH_STATUSES.IDLE;
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.user.state = FETCH_STATUSES.FULFILLED;
      state.user.user = action.payload;
    });
    builder.addCase(fetchCurrentUser.rejected, (state, action) => {
      state.user.state = FETCH_STATUSES.FAILED;
      state.user.user = null;
    });
  }
});

export const selectPanel = (state) => state.organon.panel;

export const selectCurrentUser = (state) => state.organon.user.user;

export const selectCurrentUserStatus = (state) => state.organon.user.status;

export const { setPanel } = organonSlice.actions;

export default organonSlice.reducer