import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFoundUsers, fetchUsersByName, clearFoundUsers } from './userSlice';
import HelpShortcut from '../../utils/HelpShortcut';
import { translate as t } from '../../utils/translate';

export function UserSelector ({ onSelect }) {
  const dispatch = useDispatch(),
    foundUsers = useSelector(selectFoundUsers),
    fieldRef = useRef(),
    [query, setQuery] = useState(''),
    [hasFocus, setHasFocus] = useState(false);
    
  useEffect(() => {
    if (query.length > 0) {
      dispatch(fetchUsersByName(query));
    }
    else {
      dispatch(clearFoundUsers());
    }
  }, [query]);

  return <div style={ { position: 'relative' } }>
    <input ref={ fieldRef } type="text" value={ query } onChange={ (e) => setQuery(e.target.value) } onFocus={ () => setHasFocus(true) } onBlur={ () => setHasFocus(false) } />
    { hasFocus && foundUsers && 
    <ul className="score-meta--autocomplete">
      { foundUsers.map((user) => (
        <li
          /* Avoid triggering blur on the input field */
          onMouseDown={ (e) => e.preventDefault() } 
          onClick ={ (e) => {
            /* Prevent a parent element from triggering focus on input field */
            e.preventDefault();
            fieldRef?.current.blur();
            onSelect(user);
          }
          }>{ user.username }</li>
      ))}
    </ul>
    }
  </div>
}


export function ShareWith ({ onAdd, onRemove, users }) {
  return <>
    { users && users.length > 0 &&
      <>
        <HelpShortcut target="partager-avec">{ t('Partagé avec') }</HelpShortcut>
        <ul className="score-meta__shared-with">
          { users.map((user) => <li>{ user.username } <button onClick={ () => onRemove(user) }>x</button></li>)}
        </ul> 
      </>
    }
    <label>
      <HelpShortcut target="partager-avec">{ t('Partager avec') }</HelpShortcut>
      <UserSelector onSelect={ onAdd } />
    </label>
  </>
}