import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTranslations } from '../../services/translationsApi';
import { FETCH_STATUSES } from './constants';

const initialState = {
  selectedLanguage: null,
  translations: [],
  status: FETCH_STATUSES.IDLE,
  error: ''
}

export const selectTranslation = createAsyncThunk(
  'language/selectTranslation',
  async (language) => {
    return await getTranslations(language);
  }
)

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(selectTranslation.pending, (state, action) => {
        state.status = FETCH_STATUSES.PENDING
      })
      .addCase(selectTranslation.fulfilled, (state, action) => {
        state.status = FETCH_STATUSES.FULFILLED
        state.selectedLanguage = action.payload.language
        state.translations = action.payload.translations
        state.errors = ''
      })
      .addCase(selectTranslation.rejected, (state, action) => {
        state.status = FETCH_STATUSES.FAILED
        state.error = action.error.message
      })
  }
})

const localSelectors = {
  getTranslationsState: (state) => state,
  getTranslation: (state, key) => {
    if (state && state.status === FETCH_STATUSES.FULFILLED && key in state.translations) {
      return state.translations[key]
    }

    return key;
  }
}

/** Adapter for selector state */
const adaptedSelector = (selector) => (state, ...args) => {
  return selector(state.translations, ...args)
}

export const directTranslation = localSelectors.getTranslation;

export const getTranslation = adaptedSelector(localSelectors.getTranslation);

export const getTranslationsState = adaptedSelector(localSelectors.getTranslationsState);

export default translationsSlice.reducer;