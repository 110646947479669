import { RANGE_TYPES } from '../constants';

export function LineBoucle ({ boucle })  {
  return <span className="boucle option">
    { boucle.type == RANGE_TYPES.exact && <>{ boucle.value }</> }
    { boucle.type == RANGE_TYPES.minimal && <>{ boucle.value }+</> }
    { boucle.type == RANGE_TYPES.range && <>{ boucle.value[0] }-{ boucle.value[1] }</> }
  </span>
}

export default LineBoucle;