import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectScore } from './scoreSlice';
import { initPlayer, markCurrentLinePlayed, skipQueueEntry, updateQueueEntry } from './playerSlice';
import { selectLine, selectLineAndAncestors } from './linesSlice';
import { ActionCreators } from 'redux-undo';
import LineActant from './Line/Actant';
import LineAdresse from './Line/Adresse';
import LineAlternative from './Line/Alternative';
import LineAspect from './Line/Aspect';
import LineAttachments from './Line/Attachments';
import LineBoucle from './Line/Boucle';
import LineRepeatInlineInput from './Line/RepeatInlineInput'
import LineConnectors from './LineConnectors';
import LineCondition from './Line/Condition';
import LineDestination from './Line/Destination';
import LineTerme from './Line/Terme';
// import SelectableSublinesOf from './SelectableSublinesOf';
import { makeConnectionPointId } from '../../utils/lineconnector';
import { translate as t } from '../../utils/translate';
import LineIndications from './Line/Indications';
import { PLAYER_LINE_MODE_ACCUMULATIVE, PLAYER_LINE_MODE_SIMULTANEOUS, RANGE_TYPES, TAGS, TAG_ACCUMULATIVE_RANDOM, TAG_SUCCESSIVE_RANDOM, UNDOABLE_PLAYER_UNDO } from './constants';
import { EditableSublines } from './EditablePlayerLines';

export function PlayerSublinesOf ({ id, renderPath, mode }) {
  const line = useSelector(state => selectLine(state, id)),
        // tag and transition are consistent among siblings
        // extract the first subline and take its transition and tag
        firstSubline = useSelector((state) => selectLine(state, line.sublines[0])),
        transition = firstSubline?.transition,
        queueEntry = useSelector(state => state.player.present.queue[line.id]),
        sublines = (queueEntry.sublines !== null) ? queueEntry.sublines : line.sublines;
  
  if (sublines) {
    return <section className="sublines">
      <section className="sublines__metadata__wrapper">
        <section className="sublines__metadata">
          {/* @FIXME: hide tag when alternative is 1. Through queueEntry?  */}
          { queueEntry.tag && <span className="tag" data-tag={ queueEntry.tag } /> }
          { transition && <span className="transition" label={ t(transition) } data-transition={ transition } /> }
          {/* { line.alternative && <LineAlternative alternative={ line.alternative } /> } */}
        </section>
      </section>
      { sublines.map((id, index) => {
        if (renderPath[0] === id
          || mode === PLAYER_LINE_MODE_SIMULTANEOUS
          || (mode === PLAYER_LINE_MODE_ACCUMULATIVE && (index < line.sublines.indexOf(renderPath[0]))))
        {
          return <PlayerLine key={ id } id={ id } index={ index } renderPath={ renderPath } isLastLine={ index === sublines.length - 1 } />;
        } else if (index == 0 && index < sublines.indexOf(renderPath[0])) {
          return <PlayerLineStub line={ line } />
        }
      } ) }
      {/* { line.sublines.map((id, index) => <LineOrLinkedLine key={ id } id={id} index={index} />) } */}
    </section>;
  }
  else {
    return <></>;
  }
}

export function PlayerLineStub (line) {
    return <div
    className="axis-row--wrapper"
    key={ line.id }
    open={ false }>
    <summary className="axis-row">
      <section className="axis-row--background-wrapper">
        <LineCondition condition={ line.condition } contingent={ line.contingent } necessary_when={ line.necessary_when } />
        <section className="line__body">
          <span className="line__title">
            ...
          </span>
        </section>
      </section>
    </summary>
  </div>
}

export function PlayerLine ({ autoFocus, id, index, isLastLine, isMainLine, renderPath, selectable, selected, onChange }) {
  const dispatch = useDispatch(),
        queueEntry = useSelector(state => state.player.present.queue[id]),
        currentLine = useSelector(state => state.player.present.currentLine),
        line = useSelector(state => selectLine(state, id)),
        [ order, setOrder ] = useState(line.sublines),
        [ sublines, setSublines ] = useState((queueEntry.sublines === null) ? false : queueEntry.sublines),
        [ repeat, setRepeat] = useState((queueEntry.repeat === null) ? false : queueEntry.repeat),
        needInformationContingent = (id === currentLine && queueEntry.contingent === true),
        needInformationRepeat = (id === currentLine && queueEntry.repeat === null),
        needInformationSublines = (id === currentLine && queueEntry.sublines === null),
        needInformationOrder = (queueEntry.tag === TAG_ACCUMULATIVE_RANDOM || queueEntry.tag === TAG_SUCCESSIVE_RANDOM);

  return <div
      className="axis-row--wrapper"
      key={ line.id }
      // renderPath={ renderPath.slice(1) }
      data-module={ line.module_ }
      data-contingent={ queueEntry.contingent }
      data-id={ line.id }
      id={ line.id }
      data-has-sublines={ (line.sublines.length > 0) ? true : null }
      data-is-mainline={ isMainLine }
      data-is-last-line={ isLastLine }
      data-selectable={ selectable }
      data-selected={ selected }
    >
    <summary className="axis-row">
      { selectable && <input type="checkbox" autoFocus={ autoFocus } className="axis-row--selectable--input" checked={ selected } onChange={ (e) => onChange(!selected) } /> }
      <section className="axis-row--background-wrapper">
        <LineCondition condition={ line.condition } contingent={ line.contingent } necessary_when={ line.necessary_when } />
        
        { line.actant && <LineActant actant={ line.actant } /> }
        
        <section className="line__body">
          <span className="line__title" id={ makeConnectionPointId(line.id) }>
            { line.title }
          </span>

          <LineAspect aspect={ line.aspect } />
          
          { ( line.indications || line.commandement || line.code ) && <LineIndications indications={ line.indications} commandement={ line.commandement } destination={ line.destination } code={ line.code } /> }
        
          { line.attachments.length > 0 && <LineAttachments attachmentIds={ line.attachments } /> }
        
        </section>


        { line.adresse && <>
          <LineAdresse adresse={ line.adresse } />
          { line.destination && <LineDestination destination={ line.destination } />}
        </> }
        
        { needInformationRepeat && <LineRepeatInlineInput autoFocus={ true } repeat={ line.boucle } onChange={ (valid, repeat) => { console.log(valid, repeat); setRepeat((valid) ? repeat : false) } } /> }
        { !needInformationRepeat && line.boucle && <LineBoucle boucle={ { type: RANGE_TYPES.exact, value: queueEntry.repeat - queueEntry.count } } />}

        { line.terme && <LineTerme terme={ line.terme } /> }
      </section>
      { (needInformationContingent) && <button onClick={() => dispatch(skipQueueEntry(line.id))}>{ t('Skip') }</button>}
      { (needInformationRepeat || needInformationSublines || needInformationContingent || needInformationOrder) && <button disabled={(repeat===false || sublines===false)} onClick={ () => dispatch(updateQueueEntry({ id: line.id, repeat: repeat, sublines: sublines, contingent: false, order: order }))}>{ t('Perform') }</button>}
    </summary>  
    { (() => {
      if (renderPath.length > 0) {
        let orderable = needInformationOrder;
        let selectable = (line.sublines.length > 0 && needInformationSublines); 
        if (orderable || selectable) {
          return <EditableSublines
            autoFocus={ !needInformationRepeat }
            parent={ line.id }
            lines={ order }
            tag={ queueEntry.tag }
            transition={ queueEntry.transition }
            alternative={ queueEntry.alternative }
            renderPath={ renderPath.slice(1) }
            orderable={ orderable }
            selectable={ selectable }
            onOrderChange={ (newOrder) => { setOrder(newOrder) } }
            onSelectionChange={ (valid, sublines) => { setSublines((valid) ? sublines : false) } } />
        }
        // else if  {
        //   return <OrderableSublines parent={line.id} order={ line.sublines } renderPath={ renderPath.slice(1) } onChange={ (newOrder) => console.log(newOrder) } />
        //   return <SelectableSublinesOf autoFocus={ !needInformationRepeat } id={ line.id } renderPath={ renderPath.slice(1) }  />
        // }
        else {
          return <PlayerSublinesOf id={ line.id } renderPath={ renderPath.slice(1) } mode={ queueEntry.mode } />
        }
      }
    })() }
  </div>;
}


export function Player () {
  const dispatch = useDispatch(),
        canStep = useSelector(state => state.player.present.canStep),
        currentLine = useSelector(state => state.player.present.currentLine),
        renderPath = useSelector(state => (currentLine) ? selectLineAndAncestors(state, currentLine) : []).map((line) => line.id),
        score = useSelector(selectScore),
        hasHistory = useSelector(state => Object.keys(state.player.past).length > 0);
      
  renderPath.reverse();
  
  if (score) {
    if (currentLine) {
      return <div className="player">
        <button disabled={ !hasHistory } onClick={ () => dispatch({ type: UNDOABLE_PLAYER_UNDO }) }>Previous</button>
        <button onClick={ (e) => { dispatch(markCurrentLinePlayed()); } } disabled={ !canStep }>Next</button>
        <div className="score__container">
          <div className="score__lines__wrapper">
            <PlayerLine id={ renderPath[0] } index={ 0 } isMainLine={ true } renderPath={ renderPath } />
          </div>
        </div>
      </div>
    } 
    else {
      dispatch(initPlayer());
      // dispatch(makeQueue());
      // dispatch(goToNextLine());
      return <></>;
    }
  }
  else {
    return <></>
  }
}