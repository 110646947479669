import React from 'react';
import { translate as t } from '../../../utils/translate';
import HelpShortcut from '../../../utils/HelpShortcut';
import { RANGE_TYPES } from '../constants';
import { useDispatch } from 'react-redux';
import { disableAlternative, enableAlternative, setAlternativeRangeType, setAlternativeValue } from '../lineEditorSlice';

/**
 * Gets a prop, with one of them a RangeValue
 * @param { { alternative: RangeValueShape } } param0 
 * @returns 
 */

export default function AlternativeField ({ alternative }) {
  const dispatch = useDispatch();
  return <section className="fieldgroup">
    <label className="inline">
      <HelpShortcut target="#alternative">{ t('Alternative') }</HelpShortcut>
      <input type="checkbox" name="toggle-range-field" checked={ (alternative) ? true : false } onChange={ (e) => dispatch((e.target.checked) ? enableAlternative({
          type: RANGE_TYPES.exact,
          value: 1
        }) : disableAlternative()) } />
    </label>
    { alternative && <>
      <label className="inline">
        { t('choisir') }
        <select name="type"  value={ (alternative) ? alternative.type : alternative } onChange={ (e) => dispatch(setAlternativeRangeType(e.target.value)) }>
          {/* <option value={ RANGE_TYPES.undetermined } >
            { t('indéterminé') }
          </option> */}
          <option value={ RANGE_TYPES.exact } >
            { t('exactement') }
          </option>
          <option value={ RANGE_TYPES.minimal } >
            { t('au moins') }
          </option>
          <option value={ RANGE_TYPES.range } >
            { t('entre') }
          </option>
        </select>
      </label> 
      { alternative && alternative.type == RANGE_TYPES.exact && 
        <section data-range-type="<%= rangeType.exact %>" className="type-fields inline">
          <input type="number" name="exact" min="1" className="small-number" value={ alternative.value } onChange={ (e) => dispatch(setAlternativeValue(parseInt(e.target.value))) } />
        </section> }
      { alternative && alternative.type == RANGE_TYPES.minimal && 
        <section data-range-type="<%= rangeType.minimal %>" className="type-fields inline">
          <input type="number" name="minimal" min="1" className="small-number" value={ alternative.value } onChange={ (e) => dispatch(setAlternativeValue(parseInt(e.target.value))) } />
        </section> }
      { alternative && alternative.type == RANGE_TYPES.range && 
        <section data-range-type="<%= rangeType.range %>" className="type-fields inline">
          <input type="number" name="range-min" min="1" className="small-number" value={ alternative.value[0] } onChange={ (e) => dispatch(setAlternativeValue([ parseInt(e.target.value), alternative.value[1] ])) } />
          { t('et') }
          <input type="number" name="range-max" min="2" className="small-number" value={ alternative.value[1] } onChange={ (e) => dispatch(setAlternativeValue([ alternative.value[0], parseInt(e.target.value) ])) } />
        </section> }
      { t('axes') }
    </> }
  </section>
}