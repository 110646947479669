import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: '/dev-api' }),
  endpoints: builder => ({
    getScore: builder.query({
      query: (scoreId) => `/score/${ scoreId }.json`
    })
  })
})

export const { useGetScoreQuery } = apiSlice