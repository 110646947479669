import React from 'react';
import { useDispatch } from 'react-redux';
import { expandUntill } from './interfaceSlice';

export function ScoreLevelSlider ({ levels }) {
  const dispatch = useDispatch(),
        range = [...Array(levels).keys()];
  
  return <ol className="score__slider slider">
    { range.map((level) => (
      <li key={ level } className="slider__level" data-value={ level } onClick={ () => dispatch(expandUntill({ level }))}>
          <span className="slider__label">
            { level }
          </span>
      </li>
    )) }
  </ol>;
}