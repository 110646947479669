import { RANGE_TYPES } from "../constants";

export function shouldShowTag(line) {
  if (line.alternative
    && line.alternative.type == RANGE_TYPES.exact
    && line.alternative.value == 1
  ) {
    return false;
  }
  return true;
}