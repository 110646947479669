import React from 'react';
import AlternativeField from './Editor/Alternative';
import AttachmentField from './Editor/Attachment';
import HelpShortcut from '../../utils/HelpShortcut';
import LoopField from './Editor/Loop';
import PointToField from './Editor/PointTo';
import TransitionField from './Editor/Transition';
import { translate as t } from '../../utils/translate';
import { TAGS, ASPECTS, LINE_TYPES, CONTINGENT_MODES } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { deactivateEditor, disableAspect, enableAspect, 
  lineEditorLine, lineEditorMode, lineEditorVisible,
  setActant, setAdresse, setAspect, setCode, setCommandement, setCondition,
  setContingent, setContingentMode, setcontingentValue,
  setDestination, setIndications, setLineType, setModule, setNecessaryWhen, setTag, 
  setTerme, setTitle } from './lineEditorSlice';
import { setImperative } from './lineEditorSlice';
import { setLine } from './linesSlice';
import { LinkField } from './Editor/Link';
import AttachmentListField from './Editor/AttachmentList'


export default function LineEditor () {
  const dispatch = useDispatch(),
        visible = lineEditorVisible,
        line = useSelector(lineEditorLine),
        mode = useSelector(lineEditorMode);

  if (visible && line) {
    return <section className="line__editor">
      <fieldset>
        <HelpShortcut target="type">{ t('Type d\'axe') }</HelpShortcut>
        <select name="typr" value={ line.type } onChange={ (e) => dispatch(setLineType(e.target.value)) } >
          <option value={ LINE_TYPES.REGULAR }>{ t('normal') }</option>
          <option value={ LINE_TYPES.LINK }>{ t('lié') }</option>
        </select>
      </fieldset>

      { line.type == LINE_TYPES.LINK && <>
        <fieldset>
          <LinkField lineId={ line.id } link={ line.link } />
        </fieldset>
      </>}
      { line.type != LINE_TYPES.LINK && <>
        <fieldset>
          <label>
            <HelpShortcut target="operateur">{ t('Opérateur') }</HelpShortcut>
            <select name="tag" value={ line.tag } onChange={ (e) => dispatch(setTag(e.target.value)) } >
              { TAGS.map((tag) => <option key={ tag } value={ tag }>{ tag }</option>) }
            </select>
          </label>
          { (line.tag == '>' || line.tag == '|') && <TransitionField transition={ line.transition } /> }
          <label>
            <HelpShortcut target="nom-de-laxe">{ t('Axe') }</HelpShortcut>
            <input type="text" name="title" value={ line.title } onChange={ (e) => dispatch(setTitle(e.target.value)) }/>
            <label className="inline">
              <input type="checkbox" name="contingent" checked={ line.contingent } onChange={ (e) => dispatch(setContingent(e.target.checked)) } />
              <HelpShortcut target="axe-contingent">{ t('Axe Contingent') }</HelpShortcut>
            </label>
          </label>
          {/* <!-- contingent --> */}
          { line.contingent && <section className="fieldgroup">
            <label className="inline">
              <select className="js-help" onChange={ (e) => { dispatch(setContingentMode(e.target.value)) }} value={ line.contingent.mode }>
                { Object.values(CONTINGENT_MODES).map(mode => (
                  <option key={ mode } value={ mode }>{ t(mode) }</option>
                ))}
              </select>
            </label>
            <label  className="inline">
              <input type="text" value={ line.contingent.value } onChange={ (e) => dispatch(setcontingentValue(e.target.value)) } />
              &nbsp;
              { line.contingent.mode == CONTINGENT_MODES.CONDITION && <>{ t("je peux si ; sinon je passe") }</> }
              { line.contingent.mode == CONTINGENT_MODES.OBLIGATION && <>{ t("je dois si ; sinon je peux effectuer ou passer") }</>}
              { line.contingent.mode == CONTINGENT_MODES.IMPERTATIF && <>{ t("je dois si et seulement si ; sinon je passe") }</>}
            </label>
            {/* <label className="inline">
                <span className="label-text"></span>
                <HelpShortcut target="necessary-when">{ t('Nécessaire si') }</HelpShortcut>
                <input type="text" name="necessary-when" value={ line.necessary_when } onChange={ (e) => dispatch(setNecessaryWhen(e.target.value)) } />
              </label> */}
          </section> }
          {/* <!-- condition --> */}
          { ! line.contingent && <label>
            <HelpShortcut target="condition">{ t('Déclencheur') }</HelpShortcut>
            <input type="text" name="condition" value={ line.condition } onChange={ (e) => dispatch(setCondition(e.target.value)) } />
          </label> }
          {/* <!-- terme --> */}
          <label>
            <HelpShortcut target="terme">{ t('Terme') }</HelpShortcut>
            <input type="text" name="terme" value={ line.terme } onChange={ (e) => dispatch(setTerme(e.target.value)) } />
          </label>
          {/* <label className="inline">
            { t('Impérative') }
            <input type="checkbox" name="imperative" checked={ line.imperative } onChange={ (e) => dispatch(setImperative(e.target.checked)) } />
          </label> */}
        </fieldset>
        <fieldset>
          <LoopField loop={ line.boucle } />
          
          {/* <!-- alternative [subview] --> */}
          <AlternativeField alternative={ line.alternative } />

          {/* <!-- aspect --> */}
          <section className="fieldgroup">
            <label className="inline">
              <HelpShortcut target="aspect">{ t('Aspect') }</HelpShortcut>
              <input type="checkbox" name="aspect" checked={ line.aspect } onChange={ (e) => dispatch((e.target.checked) ? enableAspect() : disableAspect() ) } />
              {line.aspect &&
                <select name="aspect-value" value={ line.aspect }  onChange={ (e) => dispatch(setAspect(e.target.value)) }>
                  { ASPECTS.map(aspect => <option key={ aspect }value={ aspect }>{ t(aspect) }</option>)}
                </select>}
            </label>
          </section>

          {/* <!-- modulé --> */}
          <label>
            <HelpShortcut target="axe-module">{ t('Axe modulé') }</HelpShortcut> 
            <input type="checkbox" name="module" checked={ line.module_ } onChange={ (e) => dispatch(setModule(e.target.checked)) }  />
          </label>

          {/* <!-- point to / feeds --> */}
          <PointToField pointTo={ line.pointTo }/>
        </fieldset>
        <fieldset>
          {/* <!-- actant --> */}
          <label>
            <HelpShortcut target="actant">{ t('Actant') }</HelpShortcut>
            <input type="text" name="actant" value={ line.actant } onChange={ (e) => dispatch(setActant(e.target.value)) } />
          </label>

          {/* <!-- adresse --> */}
          <section className="fieldgroup">
            <label className="inline">
              <HelpShortcut target="adresse">{ t('Adresse') }</HelpShortcut>
              <input type="text" name="adresse" value={ line.adresse } onChange={ (e) => dispatch(setAdresse(e.target.value)) } />
            </label>
            {/* <!-- Destination --> */}
            {line.adresse && 
              <label className="inline">
                <HelpShortcut target="destination">{ t('Destination') }</HelpShortcut>
                <input type="text" name="destination" value={ line.destination } onChange={ (e) => dispatch(setDestination(e.target.value)) }/>
              </label> }
          </section>

          {/* <!-- Commandement --> */}
          <label>
            <HelpShortcut target="commandement">{ t('Commandement') }</HelpShortcut>
            <input type="text" name="commandement" value={ line.commandement } onChange={ (e) => dispatch(setCommandement(e.target.value)) }/>
          </label>
            
        </fieldset> 

        <fieldset>
          {/* <!-- attachments --> */}
          <section data-name="attachment">
            <AttachmentListField attachmentIds={ line.attachments } />
            {/* <AttachmentField attachment={ line.attachment } /> */}
          </section>
        </fieldset>

        <fieldset>
          <section className="fields">
            {/* <!-- Code --> */}
            <label>
              <HelpShortcut target="code">{ t('Code') }</HelpShortcut>
              <input type="text" name="code" value={ line.code } onChange={ (e) => dispatch(setCode(e.target.value)) }/>
            </label>
            {/* <!-- Indications --> */}
            <label>
              <HelpShortcut target="indications">{ t('Indications') }</HelpShortcut>
              <textarea name="indications" value={ line.indications } onChange={ (e) => dispatch(setIndications(e.target.value)) }/>
            </label>
          </section>
        </fieldset>
      </> }
      <button data-name="close" onClick={ () => dispatch(deactivateEditor()) }>{ t('Annuler') }</button>
      <button onClick={
        () => {
          dispatch(setLine(line));
          dispatch(deactivateEditor());
        }
      }>{ t('OK') }</button>
      {/* <button data-name="link">{ t('créer un axe lié') }</button> */}
    </section>
  }
  else {
    return <></>
  }
}