const cache = {}

/**
 * Load a language / translations file
 * @param {str} language language code
 * @returns {{ str: str }} Translations
 */
export function getTranslations (language) {
  const promise = new Promise((resolve, reject) => {
    if (language in cache) {
      resolve(cache[language]);
    }
    else {
      fetch(`/dev-api/lang/${ language }.json`)
        .then(r => {
          r.json()
            .then(data => {
              cache[language] = data;
              resolve(cache[language]);
             })
            .catch(reject);
        })
        .catch(reject)
    }
  });

  return promise;
}