import React, { useState, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeConnectionPointId } from '../../utils/lineconnector';
import { selectScore } from './scoreSlice';
import { selectLineAndSublinesAsList, selectLineAndSublinesIdsAsList } from './linesSlice';
import { selectLinesExpandedState, selectFirstVisibleParent } from './interfaceSlice';

const connectorMargin = 30;
const connectorStyle = '2px dotted white';

export function Connector({ fromCoordinates, toCoordinates, type, offset }) {

  const ref = useRef(null);
  // const [y, setY] = useState(0);
  const [fromConnectorY, setFromConnectorY] = useState(0);
  const [fromConnectorWidth, setFromConnectorWidth] = useState(0);
  const [toConnectorLeft, setToConnectorLeft] = useState(0);
  const [toConnectorY, setToConnectorY] = useState(0);
  const [toConnectorWidth, setToConnectorWidth] = useState(0);
  const [fromConnectorLeft, setFromConnectorLeft] = useState(0);


  useLayoutEffect(() => {
    const connectorRect = ref.current?.getBoundingClientRect();
    
    if (connectorRect) {
      setFromConnectorY(fromCoordinates[1] - connectorRect.top);
      setFromConnectorWidth(connectorRect.right - fromCoordinates[0]);
      setFromConnectorLeft(fromCoordinates[0]);
      setToConnectorY(toCoordinates[1] - connectorRect.top);
      setToConnectorWidth(connectorRect.right - toCoordinates[0]);
      setToConnectorLeft(toCoordinates[0]);
    }
  }, [ fromCoordinates, toCoordinates ]);

  return <div ref={ ref } style={ {
      position: 'absolute',
      'top': 0,
      'right': 0 + offset,
      'left': 0,
      'bottom': 0,
      'pointerEvents': 'none'
    }}
    className="connector" data-type={ type }
    >
    <div style={ {
      position: 'absolute',
      right: 0,
      left: 0,
      pointerEvents: 'none',
      top: Math.min(fromConnectorY, toConnectorY),
      height: Math.abs(fromConnectorY - toConnectorY)
    } }>
      {/* From horizontal connector */}
      <div style={ {
        position: 'absolute',
        // width: fromConnectorWidth - connectorMargin - offset,
        top: (fromConnectorY < toConnectorY) ? 0 : '100%',
        right: 0,
        left: fromConnectorLeft,
        borderTop: connectorStyle
      } } className="connector connector--from"></div>
      {/* Vertical connector */}
      <div style={ {
        position: 'absolute',
        top: -2,
        right: 0,
        bottom: -2,
        borderRight: connectorStyle
      } } className="connector connector--vertical"></div>
      {/* To horizontal connector */}
      <div style = { {
        position: 'absolute',
        // width: toConnectorWidth - connectorMargin - offset,
        left: toConnectorLeft,
        bottom: (fromConnectorY < toConnectorY) ? 0 : '100%',
        right: 0,
        borderTop: connectorStyle
      } } claclassNamess="connector connector--from">
        <span style={ { position: 'absolute', top: '-.75em', left: '-1ch' }}>&lt;</span>
      </div>
    </div>
  </div>;
}

export function LineConnector ({ fromId, toId, type, offset }) {
  const [fromCoordinates, setFromCoordinates] = useState([ 0, 0 ]);
  const [toCoordinates, setToCoordinates] = useState([ 0, 0 ]);
  const scoreLinesExpandedState = useSelector(selectLinesExpandedState);
  const score = useSelector(selectScore);
  /**
   * Transform score into a list of lineIds, when order changes,
   * or a new line is added the connectors should be redrawn.
   */
  const scoreLinesList = useSelector((state) => selectLineAndSublinesIdsAsList(state, score.mainline));
  const fromVisibleLine = useSelector(state => selectFirstVisibleParent(state, fromId));
  const toVisibleLine = useSelector(state => selectFirstVisibleParent(state, toId));

  useLayoutEffect(() => {
    const fromConnectionPoint = document.getElementById(makeConnectionPointId(fromVisibleLine.id)),
          toConnectionPoint = document.getElementById(makeConnectionPointId(toVisibleLine.id));

    if (fromConnectionPoint && toConnectionPoint) {
      const fromConnectionPointRect = fromConnectionPoint.getBoundingClientRect(),
            toConnectionPointRect = toConnectionPoint.getBoundingClientRect();
      const fromVerticalOffset = (fromVisibleLine.id !== fromId) ? 30 : 0,
            fromHorizontalOffset = (fromVisibleLine.id !== fromId) ? 250 : 125,
            toVerticalOffset = (toVisibleLine.id !== toId) ? 30 : 0,
            toHorizontalOffset = (toVisibleLine.id !== toId) ? 250 : 125;
      setFromCoordinates([ fromConnectionPointRect.right + fromHorizontalOffset, fromConnectionPointRect.top + fromConnectionPointRect.height / 2 + fromVerticalOffset]);
      setToCoordinates([ toConnectionPointRect.right + toHorizontalOffset, toConnectionPointRect.top + toConnectionPointRect.height / 2 + toVerticalOffset ]);
    }
  }, [ scoreLinesExpandedState, scoreLinesList ]);
  
  if (fromVisibleLine && toVisibleLine && fromVisibleLine.id !== toVisibleLine.id && fromCoordinates && toCoordinates) {
    return <Connector fromCoordinates={ fromCoordinates } toCoordinates={ toCoordinates } type={ type } offset={ offset } />
  }
}

export function LineConnectors ({ connectors }) {
  return <div style={ {
    position: 'absolute',
    top: 0,
    right: 20,
    bottom: 0,
    left: 0,
    pointerEvents: 'none'
  } }>
    { connectors.map((connector, index) => 
        <LineConnector key={ `${connector.from}--${connector.to}` } fromId={ connector.from  } type={ connector.type } toId={ connector.to } offset={ index * 40 } /> ) }
  </div>
}

export default LineConnectors