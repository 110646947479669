import React from 'react';
import { translate as t } from '../../../utils/translate';
import HelpShortcut from '../../../utils/HelpShortcut';
import { LINK_MODES, INDENT } from '../constants';
import { selectLinesAndSublinesAsListWithLevels } from '../linesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setLinkLine, setLinkMode } from '../lineEditorSlice';
import { selectScore } from '../scoreSlice';

/*

  PointTo field has two modes:
  - feeds
  - goto

*/

export function LinkField ({ lineId, link }) {
  const dispatch = useDispatch(),
        score = useSelector(selectScore),
        flattenedScoreLines = useSelector((state) => selectLinesAndSublinesAsListWithLevels(state, score.mainline)),
        lineIndex = flattenedScoreLines.findIndex(([_, line]) => line.id === lineId),
        eligibleLine = (line, index) => (
          (line.id === lineId // The line itself cannot be selected
            // If link is in resume of keep mode only lines before the line can be selected
            || (
              (link.mode == LINK_MODES.RESUME || link.mode == LINK_MODES.KEEP)
              && index >= lineIndex))
        )

  return <>
    <label>
      <HelpShortcut target="axe-lie-prefixe">{ t('préfixe') }</HelpShortcut>
      <select name="type" value={ (link) ? link.mode : link } onChange={ (e) => dispatch(setLinkMode(e.target.value)) }>
      <option value={ null } >
          { t('choisir') }
        </option>
        <option value={ LINK_MODES.RESUME } >
          { t('resume') }
        </option>
        <option value={ LINK_MODES.KEEP } >
          { t('keep') }
        </option>
        <option value={ LINK_MODES.GOTO } >
          { t('goto') }
        </option>
      </select>
    </label>
    <label>
      <HelpShortcut target="axe-lie">{ t('axe lié') }</HelpShortcut>
      <select name="line" value={ (link) ? link.line : link } onChange={ (e) => dispatch(setLinkLine(e.target.value)) }>
        <option value={ null }>{ t('choisir') }</option>
        { flattenedScoreLines.map(([ level, line ], index) => <option key={ line.id } id={ line.id } value={ line.id } disabled={  eligibleLine(line, index)  }>{ INDENT.repeat(level) + line.title }</option> ) }
      </select>
    </label>
  </>;
}

export default LinkField